import React from "react";

const ThemeSunIcon = () => {
  return (
    <svg
      width="36"
      height="20"
      viewBox="0 0 36 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="20" rx="10" fill="#D9D9D9" />
      <g filter="url(#filter0_d_9394_54344)">
        <circle cx="10" cy="10" r="10" fill="url(#paint0_linear_9394_54344)" />
      </g>
      <path
        d="M6.87512 6.71877C6.87512 5.88088 6.99826 5.0341 7.3126 4.3125C5.2227 5.22225 3.8125 7.35658 3.8125 9.78113C3.8125 13.0431 6.45693 15.6875 9.71887 15.6875C12.1434 15.6875 14.2778 14.2773 15.1875 12.1874C14.4659 12.5017 13.6184 12.6249 12.7812 12.6249C9.5193 12.6249 6.87512 9.9807 6.87512 6.71877Z"
        fill="#0F172A"
      />
      <defs>
        <filter
          id="filter0_d_9394_54344"
          x="0"
          y="0"
          width="22"
          height="22"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9394_54344"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9394_54344"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_9394_54344"
          x1="10"
          y1="9.50694e-07"
          x2="23.4422"
          y2="10.7453"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1EF7DD" />
          <stop offset="1" stopColor="#6553FB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ThemeSunIcon;
