import React from "react";

const SidebarResearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className="stroke"
    >
      <path
        d="M11.5 21.25C16.7467 21.25 21 16.9967 21 11.75C21 6.50329 16.7467 2.25 11.5 2.25C6.25329 2.25 2 6.50329 2 11.75C2 16.9967 6.25329 21.25 11.5 21.25Z"
        stroke="url(#paint0_linear_9369_46248)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 22.25L20 20.25"
        stroke="url(#paint1_linear_9369_46248)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9369_46248"
          x1="24.4181"
          y1="16.2511"
          x2="6.07177"
          y2="-0.215708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9369_46248"
          x1="22.3598"
          y1="21.7238"
          x2="20.4286"
          y2="19.9905"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SidebarResearchIcon;
