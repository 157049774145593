import React from "react";

const MobileLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="32"
      viewBox="0 0 35 32"
      fill="none"
    >
      <g clipPath="url(#clip0_5258_814)">
        <path
          d="M34.5158 31.8723H29.6197C29.6197 29.9788 28.434 28.1582 26.2778 26.7536C23.9283 25.2213 20.7688 24.3749 17.3795 24.3749C13.9902 24.3749 10.8307 25.2213 8.48114 26.7536C6.32496 28.1582 5.13559 29.9751 5.13559 31.8723H0.239502C0.239502 30.2232 0.732029 28.6289 1.69884 27.1331C2.60364 25.7394 3.88421 24.4989 5.50408 23.4409C8.70369 21.3541 12.9212 20.2048 17.3795 20.2048C21.8378 20.2048 26.0553 21.3541 29.2549 23.4409C30.8747 24.4989 32.1517 25.7394 33.0565 27.1331C34.0269 28.6289 34.5158 30.2232 34.5158 31.8723Z"
          fill="#1AABE7"
        />
        <path
          d="M6.2374 26.5382C5.50773 28.2238 5.13924 30.0187 5.13924 31.8721H0.239502C0.239502 31.8101 0.24315 31.7481 0.24315 31.686C0.279633 30.1027 0.768513 28.5703 1.69884 27.1329C2.60364 25.7392 3.88421 24.4988 5.50408 23.4408C6.95612 22.4922 8.62342 21.737 10.4257 21.197C9.99156 21.529 9.57199 21.8866 9.18162 22.2696C7.92659 23.4955 6.93788 24.9293 6.2374 26.5382ZM34.5158 31.686C34.4793 30.1027 33.9904 28.5703 33.0565 27.1329C32.1553 25.7392 30.8747 24.4988 29.2549 23.4408C27.8028 22.4922 26.1355 21.737 24.3332 21.197C24.7674 21.529 25.187 21.8866 25.5773 22.2696C26.8324 23.4955 27.8247 24.9293 28.5179 26.5382C29.2512 28.2238 29.6197 30.0187 29.6197 31.8721H34.5158C34.5158 31.8101 34.5158 31.7481 34.5158 31.686Z"
          fill="#3A85E9"
        />
        <path
          d="M34.5158 31.8721H29.6197C29.6197 28.1143 29.2512 24.4696 28.5179 21.0475C27.8247 17.7858 26.8324 14.8708 25.5773 12.3826C24.3953 10.0404 22.9906 8.15054 21.5167 6.91375C20.1595 5.77911 18.7695 5.20267 17.3795 5.20267C15.9931 5.20267 14.5994 5.77911 13.2422 6.91375C11.7683 8.15054 10.3637 10.0404 9.18162 12.3826C7.92659 14.8708 6.93788 17.7858 6.24105 21.0475C5.50773 24.4733 5.13924 28.1143 5.13924 31.8721H0.239502C0.239502 23.7327 1.85937 16.0383 4.80359 10.2155C6.29942 7.24575 8.07617 4.89256 10.0791 3.21431C11.1627 2.30587 12.3119 1.60174 13.4903 1.12381C14.7563 0.613037 16.0624 0.354004 17.3795 0.354004C18.6965 0.354004 20.0026 0.613037 21.2686 1.12381C22.447 1.60174 23.5963 2.30587 24.6798 3.21431C26.6828 4.89256 28.4595 7.24575 29.9554 10.2155C32.8959 16.0383 34.5158 23.7327 34.5158 31.8721Z"
          fill="#1AABE7"
        />
      </g>
      <defs>
        <clipPath id="clip0_5258_814">
          <rect width="35" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MobileLogo;
