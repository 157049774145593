import React from "react";

const TelegramIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="telegram-icon fill"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9922 2.45819L12.6163 13.8864C12.5604 14.1562 12.244 14.2785 12.0207 14.1163L8.77742 11.7616C8.58054 11.6186 8.31205 11.6262 8.12356 11.7799L6.32535 13.2457C6.11668 13.4164 5.8022 13.3212 5.72229 13.0642L4.47367 9.04865L1.24778 7.8447C0.920089 7.72194 0.917289 7.25932 1.24404 7.13346L14.4832 2.02615C14.7638 1.91762 15.0534 2.16348 14.9922 2.45819ZM5.52017 8.40779L11.8248 4.52483C11.938 4.45528 12.0546 4.60847 11.9572 4.6987L6.75417 9.5352C6.57123 9.70538 6.45332 9.93333 6.41986 10.1806L6.2427 11.4941C6.2191 11.6694 5.97288 11.6867 5.92439 11.5171L5.24275 9.12192C5.1648 8.84872 5.27853 8.5569 5.52017 8.40779Z"
        fill="url(#paint0_linear_8188_50540)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8188_50540"
          x1="14.1588"
          y1="6.99"
          x2="6.24612"
          y2="-3.15578"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TelegramIcon;
