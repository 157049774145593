import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, takeLatest, all, put } from "redux-saga/effects";
import { INetworkInfo, INetworkInfoQuery } from "../../@types/redux";
import NetWorkInfoAPI from "../../utils/api/NetworkInfoAPI";

export interface INetworkStat {
  loading: boolean;
  latest_block: number;
  eth_price: number;
  gas_price: number;
}

const initialState: INetworkStat = {
  loading: true,
  latest_block: 0,
  eth_price: 0,
  gas_price: 0,
};

const networkStatsSlice = createSlice({
  name: "networkStats",
  initialState,
  reducers: {
    getData: (state, _: PayloadAction<INetworkInfoQuery>) => {
      state.loading = true;
    },

    setData: (state, action: PayloadAction<INetworkInfo>) => {
      state.loading = false;
      state.latest_block = action.payload.latest_block;
      state.eth_price = action.payload.eth_price;
      state.gas_price = action.payload.gas_price;
    },
  },
});

const networkStatsActions = networkStatsSlice.actions;
const networkStatsReducer = networkStatsSlice.reducer;

export { networkStatsActions, networkStatsReducer };

// Sagas

function* getNetworkInfo(action: PayloadAction<INetworkInfoQuery>): any {
  const res = yield call(NetWorkInfoAPI.getNetworkInfo as any, {
    ...action.payload,
  });
  if (!res.error) {
    yield put(
      networkStatsActions.setData({
        ...res.data,
      })
    );
  }
}

export function* networkStatsSaga() {
  yield all([takeLatest(networkStatsActions.getData, getNetworkInfo)]);
}
