import React from "react";
import { Outlet } from "react-router";
import { LocalStorages } from "../../utils/localStorages";
import TutorialDialog from "../Feature/TutorialDialog";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";

import "./index.scss";

const LayoutContainer = () => {
  const showTutorial = LocalStorages.getTutorialStatus();

  return (
    <div className="layout-container">
      <Header hideSearchBar={false} />
      <div className="layout-content-container">
        <Sidebar />
        <div className={`content-container collapsed`}>
          {showTutorial && <TutorialDialog />}
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LayoutContainer;
