import {
  IAuthAccessParams,
  IForgotPasswordParams,
  IResetPasswordParams,
} from "../../@types/api";
import { LocalStorageKeys } from "../localStorages";
import { BaseAPI } from "./BaseAPI";

export class AuthAPI {
  static signin = async (data: IAuthAccessParams) => {
    return BaseAPI.callAPI({
      url: "/accounts/auth/access/",
      method: "post",
      data: data,
      needToken: false,
    });
  };

  static signout = async () => {
    const refreshToken = localStorage.getItem(LocalStorageKeys.refresh_token);
    return BaseAPI.callAPI({
      url: "/accounts/auth/signout/",
      method: "post",
      data: {
        refresh_token: refreshToken,
      },
    });
  };

  static forgotPassword = async ({ email }: IForgotPasswordParams) => {
    return BaseAPI.callAPI({
      url: "/accounts/auth/forgot_password/",
      method: "post",
      data: {
        email: email,
      },
      needToken: false,
    });
  };

  static resetPassword = async ({ code, password }: IResetPasswordParams) => {
    return BaseAPI.callAPI({
      url: "/accounts/auth/reset_password/",
      method: "post",
      data: {
        code: code,
        password: password,
      },
      needToken: false,
    });
  };

  static getConnectedUserCnt = async () => {
    return BaseAPI.callAPI({
      url: "/accounts/total_num/",
      needToken: false,
    });
  };
}
