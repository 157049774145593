import { AlertColor } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

interface ISnackbarState {
  open: boolean;
  content: string;
  color: AlertColor;
  duration: number;
}

const initialState: ISnackbarState = {
  open: false,
  content: "",
  color: "success",
  duration: 2000,
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackBar: (state, action) => {
      state.open = true;
      state.content = action.payload.content;
      state.color = action.payload.color;
      if (action.payload.duration) state.duration = action.payload.duration;
    },

    closeSnackbar: (state, _) => {
      state.open = false;
    },
  },
});

export const snackbarActions = snackbarSlice.actions;
export const snackbarReducer = snackbarSlice.reducer;
