import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, all, takeLatest } from "redux-saga/effects";
import {
  INewWalletAccumulations,
  INewWalletAccumulationsQuery,
} from "../../@types/redux";
import { AccumulationsAPI } from "../../utils/api/AccumulationsAPI";

interface INewWalletAccumulationsState {
  isRefreshing: boolean;
  loading: boolean;
  total_count: number;
  total_page: number;
  results: [];
  query: {
    chain: string;
    page_size: number;
    page: number;
    ordering: string;
  };
}

export const newWalletAccumulationsDefaultQuery = {
  chain: "",
  page_size: 20,
  page: 1,
  ordering: "-volume_1hour",
};

const initialState: INewWalletAccumulationsState = {
  isRefreshing: false,
  loading: false,
  total_count: 0,
  total_page: 0,
  results: [],
  query: { ...newWalletAccumulationsDefaultQuery },
};

const newWalletAccumulationsSlice = createSlice({
  name: "newWalletAccumulations",
  initialState,
  reducers: {
    getList: (state, _: PayloadAction<INewWalletAccumulationsQuery>) => {
      if (!state.isRefreshing) {
        state.loading = true;
        state.results = [];
      }
    },

    setList: (state, action: PayloadAction<INewWalletAccumulations>) => {
      state.isRefreshing = false;
      state.loading = false;
      state.total_count = action.payload.total_count;
      state.total_page = action.payload.total_page;
      state.results = action.payload.results;
    },

    setQuery: (state, action: PayloadAction<INewWalletAccumulationsQuery>) => {
      state.query.chain = action.payload.chain;
      state.query.page_size = action.payload.page_size;
      state.query.page = action.payload.page;
      state.query.ordering = action.payload.ordering;
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
      state.query = {
        ...newWalletAccumulationsDefaultQuery,
      };
    },
  },
});

const newWalletAccumulationsAction = newWalletAccumulationsSlice.actions;
const newWalletAccumulationsReducer = newWalletAccumulationsSlice.reducer;

export { newWalletAccumulationsAction, newWalletAccumulationsReducer };

// Sagas

function* getNewWalletAccumulations(
  action: PayloadAction<INewWalletAccumulationsQuery>
): any {
  const res = yield call(AccumulationsAPI.getNewWalletAccumulations as any, {
    ...action.payload,
  });
  if (!res.error) {
    yield put(
      newWalletAccumulationsAction.setList({
        ...res.data,
      })
    );
  } else {
    yield put(
      newWalletAccumulationsAction.setList({
        total_count: 0,
        total_page: 0,
        results: [],
      })
    );
  }
}

export function* newWalletAccumulationsSaga() {
  yield all([
    takeLatest(newWalletAccumulationsAction.getList, getNewWalletAccumulations),
  ]);
}
