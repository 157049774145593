import React from "react";

const PerpDexDataIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="stroke"
    >
      <path
        d="M21 11L13.2 18.9L9.1 14.8L3 21M21 15V11H17"
        stroke="url(#paint0_linear_9358_118168)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 3L4 11M3 4.5C3 5.3 3.7 6 4.5 6C5.3 6 6 5.3 6 4.5C6 3.7 5.3 3 4.5 3C3.7 3 3 3.7 3 4.5ZM8 9.5C8 10.3 8.7 11 9.5 11C10.3 11 11 10.3 11 9.5C11 8.7 10.3 8 9.5 8C8.7 8 8 8.7 8 9.5Z"
        stroke="url(#paint1_linear_9358_118168)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_118168"
          x1="24.2382"
          y1="18.369"
          x2="15.5453"
          y2="4.32477"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9358_118168"
          x1="12.4392"
          y1="8.89519"
          x2="4.71443"
          y2="1.96181"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PerpDexDataIcon;
