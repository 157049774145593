import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ISmartAccumulations,
  ISmartAccumulationsQuery,
  ISmartAccumulationsSmartWhalesCategory,
} from "../../@types/redux";
import { AccumulationsAPI } from "../../utils/api/AccumulationsAPI";

interface ISmartAccumulationsState {
  isRefreshing: boolean;
  loading: boolean;
  total_count: number;
  total_page: number;
  results: [];
  query: {
    page_size: number;
    page: number;
    smart_whales_categories: string[];
    ordering: string;
    type: string;
  };
}

export const smartAccumulationsDefaultQuery = {
  page_size: 20,
  page: 1,
  smart_whales_categories: [],
  ordering: "",
  type: "top",
};

const initialState: ISmartAccumulationsState = {
  isRefreshing: false,
  loading: false,
  total_count: 0,
  total_page: 0,
  results: [],
  query: { ...smartAccumulationsDefaultQuery },
};

const smartAccumulationsSlice = createSlice({
  name: "smartAccumulations",
  initialState,
  reducers: {
    getList: (state, _: PayloadAction<ISmartAccumulationsQuery>) => {
      if (!state.isRefreshing) {
        state.loading = true;
        state.results = [];
      }
    },

    setList: (state, action: PayloadAction<ISmartAccumulations>) => {
      state.isRefreshing = false;
      state.loading = false;
      state.total_count = action.payload.total_count;
      state.total_page = action.payload.total_page;
      state.results = action.payload.results;
    },

    setQuery: (state, action: PayloadAction<ISmartAccumulationsQuery>) => {
      state.query.type = action.payload.type;
      state.query.page_size = action.payload.page_size;
      state.query.page = action.payload.page;
      state.query.ordering = action.payload.ordering;
      state.query.smart_whales_categories =
        action.payload.smart_whales_categories;
    },

    setSmartWhalesCategory: (
      state,
      action: PayloadAction<ISmartAccumulationsSmartWhalesCategory>
    ) => {
      state.query.smart_whales_categories =
        action.payload.smart_whales_categories;
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
      state.query = {
        ...smartAccumulationsDefaultQuery,
        smart_whales_categories: state.query.smart_whales_categories,
      };
    },
  },
});

const smartAccumulationsAction = smartAccumulationsSlice.actions;
const smartAccumulationsReducer = smartAccumulationsSlice.reducer;

export { smartAccumulationsAction, smartAccumulationsReducer };

// Sagas

function* getSmartAccumulations(
  action: PayloadAction<ISmartAccumulationsQuery>
): any {
  const res = yield call(
    action.payload.type === "top"
      ? AccumulationsAPI.getTopAccumulations
      : (AccumulationsAPI.getNetAccumulations as any),
    {
      ...action.payload,
    }
  );

  if (!res.error) {
    yield put(
      smartAccumulationsAction.setList({
        ...res.data,
      })
    );
  } else {
    yield put(
      smartAccumulationsAction.setList({
        total_count: 0,
        total_page: 0,
        results: [],
      })
    );
  }
}

export function* smartAccumulationsSaga() {
  yield all([
    takeLatest(smartAccumulationsAction.getList, getSmartAccumulations),
  ]);
}
