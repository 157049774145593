import React from "react";

export const AvalancheIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      className="chain-icon fill"
    >
      <path
        d="M7.73445 18.1586H3.43757C3.27849 18.1587 3.12216 18.1179 2.98415 18.0404C2.84615 17.9629 2.73127 17.8514 2.65096 17.7169C2.57065 17.5825 2.52771 17.4298 2.5264 17.274C2.52509 17.1182 2.56547 16.9648 2.64351 16.8291L10.2198 3.67016C10.2991 3.53257 10.4144 3.41809 10.5537 3.33843C10.6931 3.25877 10.8516 3.2168 11.013 3.2168C11.1743 3.2168 11.3328 3.25877 11.4722 3.33843C11.6116 3.41809 11.7268 3.53257 11.8062 3.67016L14.0766 7.59479C14.1569 7.73058 14.1992 7.88471 14.1992 8.04161C14.1992 8.19851 14.1569 8.35263 14.0766 8.48843L8.52335 17.7194C8.44267 17.8538 8.32732 17.9652 8.18887 18.0423C8.05042 18.1194 7.89372 18.1595 7.73445 18.1586Z"
        fill="url(#paint0_linear_9358_117861)"
      />
      <path
        d="M12.6296 16.8258L15.1819 12.3677C15.2615 12.2301 15.3769 12.1157 15.5164 12.0361C15.656 11.9565 15.8145 11.9146 15.976 11.9146C16.1374 11.9146 16.296 11.9565 16.4355 12.0361C16.575 12.1157 16.6905 12.2301 16.77 12.3677L19.3482 16.8241C19.4269 16.9599 19.4678 17.1136 19.467 17.2698C19.4661 17.4259 19.4235 17.5792 19.3434 17.7142C19.2632 17.8492 19.1483 17.9613 19.0101 18.0393C18.872 18.1172 18.7153 18.1584 18.5558 18.1587H13.4254C13.2658 18.1588 13.109 18.1181 12.9706 18.0404C12.8322 17.9627 12.717 17.8509 12.6365 17.716C12.556 17.5812 12.513 17.428 12.5117 17.2718C12.5105 17.1156 12.5512 16.9618 12.6296 16.8258Z"
        fill="url(#paint1_linear_9358_117861)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_117861"
          x1="16.2991"
          y1="14.2274"
          x2="2.65569"
          y2="4.66089"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9358_117861"
          x1="20.7183"
          y1="16.5158"
          x2="14.6537"
          y2="10.4526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AvalancheIcon;
