import React from "react";

function BinanceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className="chain-icon fill"
    >
      <path
        d="M18.4242 8.58465L16.1929 10.7695L18.4242 12.9544L20.6556 10.7695L18.4242 8.58465Z"
        fill="url(#paint0_linear_9358_117843)"
      />
      <path
        d="M3.57658 8.58612L1.34521 10.771L3.57658 12.9559L5.80794 10.771L3.57658 8.58612Z"
        fill="url(#paint1_linear_9358_117843)"
      />
      <path
        d="M11 15.8566L7.30125 12.2349L5.07031 14.4193L11 20.2255L16.9297 14.4193L14.6987 12.2349L11 15.8566Z"
        fill="url(#paint2_linear_9358_117843)"
      />
      <path
        d="M11 5.68484L14.6987 9.30653L16.9297 7.12207L11 1.31592L5.07031 7.12207L7.30125 9.30653L11 5.68484Z"
        fill="url(#paint3_linear_9358_117843)"
      />
      <path
        d="M10.9988 8.61349L8.79541 10.771L10.9988 12.9285L13.2022 10.771L10.9988 8.61349Z"
        fill="url(#paint4_linear_9358_117843)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_117843"
          x1="20.4699"
          y1="9.80163"
          x2="16.382"
          y2="10.027"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9358_117843"
          x1="5.62229"
          y1="9.80309"
          x2="1.53434"
          y2="10.0285"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9358_117843"
          x1="19.0632"
          y1="18.1231"
          x2="11.6109"
          y2="8.19583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9358_117843"
          x1="19.0632"
          y1="7.20419"
          x2="11.6109"
          y2="-2.72312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9358_117843"
          x1="13.0189"
          y1="9.81522"
          x2="8.98217"
          y2="10.0378"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default BinanceIcon;
