import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, takeLatest, all, put } from "redux-saga/effects";
import {
  IDexTransactionsQuery,
  IDexTransactions,
  IAdvancedFilterQuery,
} from "../../@types/redux";
import DexTransactionsAPI from "../../utils/api/DexTransactionsAPI";

export interface IDexTransactionsState {
  isRefreshing: boolean;
  loading: boolean;
  total: number;
  transactions: [];
  query: IDexTransactionsQuery;
}

const advancedFilterDefaultQuery = {
  from_ts: null,
  to_ts: null,
  bought_token_addresses: [],
  min_bought_token_amount: null,
  max_bought_token_amount: null,
  sold_token_addresses: [],
  min_sold_token_amount: null,
  max_sold_token_amount: null,
  trader_address: "",
  min_amount_usd: null,
  max_amount_usd: null,
  bought_token_list: [],
  sold_token_list: [],
  flg_smart_whales: false,
  apply_filter: false,
};

export const dexTransactionsDefaultQuery = {
  page: 1,
  page_size: 50,
  total: false,
  ordering: "",
  chain: "",
  filter_by: null,
  filter_values: "",
  filter: "",
  filter_logo: "",
  advanced_filter: advancedFilterDefaultQuery,
};

const initialState: IDexTransactionsState = {
  isRefreshing: false,
  loading: false,
  total: 0,
  transactions: [],
  query: {
    ...dexTransactionsDefaultQuery,
  },
};

const DexTransactionsSlice = createSlice({
  name: "dexTransactions",
  initialState,
  reducers: {
    getList: (state, _: PayloadAction<IDexTransactionsQuery>) => {
      if (!state.isRefreshing) {
        state.loading = true;
        state.transactions = [];
      }
    },

    setList: (state, action: PayloadAction<IDexTransactions>) => {
      state.isRefreshing = false;
      state.loading = false;
      state.total = action.payload.total;
      state.transactions = action.payload.transactions;
    },

    setQuery: (state, action: PayloadAction<IDexTransactionsQuery>) => {
      state.query = action.payload;
    },

    setAdvancedFilterQuery: (
      state,
      action: PayloadAction<IAdvancedFilterQuery>
    ) => {
      state.query.advanced_filter = action.payload;
      state.query.ordering = "";
      state.query.filter_by = null;
      state.query.filter_values = "";
      state.query.filter = "";
      state.query.filter_logo = "";
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
      state.query = { ...dexTransactionsDefaultQuery };
    },

    clearAllFilters: (state, _) => {
      state.query.advanced_filter = advancedFilterDefaultQuery;
    },
  },
});

const dexTransactionsActions = DexTransactionsSlice.actions;
const dexTransactionsReducer = DexTransactionsSlice.reducer;

export { dexTransactionsActions, dexTransactionsReducer };

// Sagas

function* getDexTransactions(action: any): any {
  const [dexTransactionsRes, totalNumberRes] = yield all([
    call(DexTransactionsAPI.getDexTransactions, { ...action.payload }),
    call(DexTransactionsAPI.getDexTransactions, {
      ...action.payload,
      total_count: true,
    }),
  ]);

  if (!dexTransactionsRes.error) {
    if (!totalNumberRes.error) {
      yield put(
        dexTransactionsActions.setList({
          transactions: dexTransactionsRes.data.transactions,
          total: totalNumberRes.data,
        })
      );
    } else {
      yield put(
        dexTransactionsActions.setList({
          transactions: dexTransactionsRes.data.transactions,
          total: 0,
        })
      );
    }
  } else {
    yield put(
      dexTransactionsActions.setList({
        transactions: [],
        total: 0,
      })
    );
  }
}

export function* dexTransactionsSaga() {
  yield all([takeLatest(dexTransactionsActions.getList, getDexTransactions)]);
}
