import React from "react";

const UserProfileIcon = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="user-profile-icon"
      >
        <path
          d="M36.6663 20C36.6663 10.8167 29.183 3.33337 19.9997 3.33337C10.8163 3.33337 3.33301 10.8167 3.33301 20C3.33301 24.8334 5.41634 29.1834 8.71634 32.2334C8.71634 32.25 8.71634 32.25 8.69967 32.2667C8.86634 32.4334 9.06634 32.5667 9.23301 32.7167C9.33301 32.8 9.41634 32.8834 9.51634 32.95C9.81634 33.2 10.1497 33.4334 10.4663 33.6667C10.583 33.75 10.683 33.8167 10.7997 33.9C11.1163 34.1167 11.4497 34.3167 11.7997 34.5C11.9163 34.5667 12.0497 34.65 12.1663 34.7167C12.4997 34.9 12.8497 35.0667 13.2163 35.2167C13.3497 35.2834 13.483 35.35 13.6163 35.4C13.983 35.55 14.3497 35.6834 14.7163 35.8C14.8497 35.85 14.983 35.9 15.1163 35.9334C15.5163 36.05 15.9163 36.15 16.3163 36.25C16.433 36.2834 16.5497 36.3167 16.683 36.3334C17.1497 36.4334 17.6163 36.5 18.0997 36.55C18.1663 36.55 18.233 36.5667 18.2997 36.5834C18.8663 36.6334 19.433 36.6667 19.9997 36.6667C20.5663 36.6667 21.133 36.6334 21.683 36.5834C21.7497 36.5834 21.8163 36.5667 21.883 36.55C22.3663 36.5 22.833 36.4334 23.2997 36.3334C23.4163 36.3167 23.533 36.2667 23.6663 36.25C24.0663 36.15 24.483 36.0667 24.8663 35.9334C24.9997 35.8834 25.133 35.8334 25.2663 35.8C25.633 35.6667 26.0163 35.55 26.3663 35.4C26.4997 35.35 26.633 35.2834 26.7663 35.2167C27.1163 35.0667 27.4663 34.9 27.8163 34.7167C27.9497 34.65 28.0663 34.5667 28.183 34.5C28.5163 34.3 28.8497 34.1167 29.183 33.9C29.2997 33.8334 29.3997 33.75 29.5163 33.6667C29.8497 33.4334 30.1663 33.2 30.4663 32.95C30.5663 32.8667 30.6497 32.7834 30.7497 32.7167C30.933 32.5667 31.1163 32.4167 31.283 32.2667C31.283 32.25 31.283 32.25 31.2663 32.2334C34.583 29.1834 36.6663 24.8334 36.6663 20ZM28.233 28.2834C23.7163 25.25 16.3163 25.25 11.7663 28.2834C11.033 28.7667 10.433 29.3334 9.93301 29.95C7.39967 27.3834 5.83301 23.8667 5.83301 20C5.83301 12.1834 12.183 5.83337 19.9997 5.83337C27.8163 5.83337 34.1663 12.1834 34.1663 20C34.1663 23.8667 32.5997 27.3834 30.0663 29.95C29.583 29.3334 28.9663 28.7667 28.233 28.2834Z"
          fill="url(#paint0_linear_8_1064)"
        />
        <path
          d="M20 11.55C16.55 11.55 13.75 14.35 13.75 17.8001C13.75 21.1834 16.4 23.9334 19.9167 24.0334C19.9667 24.0334 20.0333 24.0334 20.0667 24.0334C20.1 24.0334 20.15 24.0334 20.1833 24.0334C20.2 24.0334 20.2167 24.0334 20.2167 24.0334C23.5833 23.9167 26.2333 21.1834 26.25 17.8001C26.25 14.35 23.45 11.55 20 11.55Z"
          fill="url(#paint1_linear_8_1064)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8_1064"
            x1="21.2844"
            y1="3.33334"
            x2="21.2844"
            y2="36.6667"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10B3E8" />
            <stop offset="1" stopColor="#3F80E9" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8_1064"
            x1="20.4818"
            y1="11.55"
            x2="20.4818"
            y2="24.0334"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10B3E8" />
            <stop offset="1" stopColor="#3F80E9" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default UserProfileIcon;
