import { IGetTokenTopAccumulatorsParams } from "../../@types/api";
import { BaseAPI } from "./BaseAPI";

export class TokenTopAccumulatorsAPI {
  static getTopAccumulators = async ({
    chain,
    page = 1,
    page_size = 20,
    token_address,
    ordering,
  }: IGetTokenTopAccumulatorsParams) => {
    return BaseAPI.callAPI({
      url: `token/${token_address}/top_accumulators/`,
      params: {
        chain,
        page,
        page_size,
        ordering,
      },
    });
  };
}
