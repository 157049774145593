import { Menu, MenuItem } from "@mui/material";
import React from "react";
import "./scss/ContextMenu.scss";

interface IContextMenu {
  children: React.ReactNode;
  handleClick: () => void;
}

const ContextMenu: React.FC<IContextMenu> = ({ children, handleClick }) => {
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleClickMenu = () => {
    handleClick();
    handleClose();
  };

  return (
    <div onContextMenu={handleContextMenu}>
      {children}
      <Menu
        classes={{
          root: "context-menu",
        }}
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleClickMenu}>Token Genius</MenuItem>
      </Menu>
    </div>
  );
};

export default ContextMenu;
