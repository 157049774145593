import React from "react";

const YoutubeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      className="fill"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3457 1.44315C11.5321 1.62939 11.6663 1.86118 11.7352 2.11543C12.1315 3.71005 12.0399 6.22851 11.7429 7.88466C11.674 8.13891 11.5398 8.3707 11.3534 8.55695C11.1671 8.7432 10.9352 8.8774 10.6808 8.9462C9.7496 9.20005 6.00163 9.20005 6.00163 9.20005C6.00163 9.20005 2.25365 9.20005 1.32243 8.9462C1.06806 8.8774 0.836156 8.7432 0.649818 8.55695C0.463481 8.3707 0.32921 8.13891 0.260377 7.88466C-0.138278 6.29697 -0.028994 3.77697 0.252681 2.12313C0.321513 1.86888 0.455784 1.63708 0.642122 1.45084C0.828459 1.26459 1.06036 1.13039 1.31473 1.06159C2.24596 0.807739 5.99393 0.800049 5.99393 0.800049C5.99393 0.800049 9.7419 0.800049 10.6731 1.05389C10.9275 1.12269 11.1594 1.2569 11.3457 1.44315ZM7.91024 5.00005L4.80104 6.80004V3.20004L7.91024 5.00005Z"
        fill="url(#paint0_linear_8188_50539)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8188_50539"
          x1="14.1588"
          y1="6.99"
          x2="6.24612"
          y2="-3.15578"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default YoutubeIcon;
