import React from "react";

const ConnectIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4999 2.00018L21.4998 2C22.052 1.99998 22.4998 2.44763 22.4998 2.99988L22.5004 7.97417C22.5005 8.52645 22.0528 8.97422 21.5005 8.97428C20.9482 8.97435 20.5005 8.52668 20.5004 7.9744L20.5001 5.41407L19.2071 6.70712C18.8166 7.09766 18.1835 7.09769 17.7929 6.70718C17.4024 6.31667 17.4024 5.6835 17.7929 5.29296L19.0857 4.00009L16.5 4.00018C15.9477 4.00021 15.5 3.55251 15.5 3.00022C15.5 2.44794 15.9477 2.00021 16.4999 2.00018ZM15.5 8.00017C15.9304 8.00017 16.3126 8.2756 16.4487 8.68395L17.7906 12.7096L21.8162 14.0515C22.2246 14.1876 22.5 14.5697 22.5 15.0002C22.5 15.4306 22.2246 15.8127 21.8162 15.9489L17.7906 17.2907L16.4487 21.3164C16.3126 21.7247 15.9304 22.0002 15.5 22.0002C15.0696 22.0002 14.6874 21.7247 14.5513 21.3164L13.2094 17.2907L9.18376 15.9489C8.77542 15.8127 8.49999 15.4306 8.49999 15.0002C8.49999 14.5697 8.77542 14.1876 9.18376 14.0515L13.2094 12.7096L14.5513 8.68395C14.6874 8.2756 15.0696 8.00017 15.5 8.00017ZM15.5 12.1625L14.9487 13.8164C14.8491 14.115 14.6148 14.3493 14.3162 14.4489L12.6623 15.0002L14.3162 15.5515C14.6148 15.651 14.8491 15.8853 14.9487 16.1839L15.5 17.8379L16.0513 16.1839C16.1508 15.8853 16.3852 15.651 16.6838 15.5515L18.3377 15.0002L16.6838 14.4489C16.3852 14.3493 16.1508 14.115 16.0513 13.8164L15.5 12.1625ZM3.49988 15.0259C4.05217 15.0258 4.49994 15.4735 4.5 16.0258L4.5003 18.5861L5.79325 17.293C6.18376 16.9025 6.81693 16.9025 7.20747 17.293C7.59801 17.6835 7.59803 18.3167 7.20752 18.7072L5.91474 20.0001L8.50038 20C9.05267 20 9.5004 20.4477 9.50042 21C9.50044 21.5522 9.05274 22 8.50046 22L3.50061 22.0002C2.94836 22.0002 2.50064 21.5525 2.50057 21.0003L2.5 16.026C2.49994 15.4737 2.9476 15.026 3.49988 15.0259Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99999 3.40017C8.25825 3.40017 8.48753 3.56543 8.5692 3.81044L9.34933 6.15083L11.6897 6.93096C11.9347 7.01263 12.1 7.24191 12.1 7.50017C12.1 7.75843 11.9347 7.98771 11.6897 8.06938L9.34933 8.84951L8.5692 11.1899C8.48753 11.4349 8.25825 11.6002 7.99999 11.6002C7.74173 11.6002 7.51245 11.4349 7.43078 11.1899L6.65065 8.84951L4.31026 8.06938C4.06525 7.98771 3.89999 7.75843 3.89999 7.50017C3.89999 7.24191 4.06525 7.01263 4.31026 6.93096L6.65065 6.15083L7.43078 3.81044C7.51245 3.56543 7.74173 3.40017 7.99999 3.40017Z"
        fill="white"
      />
    </svg>
  );
};

export default ConnectIcon;
