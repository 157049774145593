import React from "react";

const TwitterXIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      className="fill"
    >
      <path
        d="M0.0292572 0.63623L4.66232 7.25494L0 12.6362H1.0493L5.13116 7.92483L8.42918 12.6362H12L7.10626 5.64523L11.4459 0.63623H10.3966L6.63742 4.97534L3.60007 0.63623H0.0292572ZM1.57233 1.46203H3.21277L10.4567 11.8103H8.81624L1.57233 1.46203Z"
        fill="url(#paint0_linear_8317_11836)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8317_11836"
          x1="14.1588"
          y1="9.47902"
          x2="2.57164"
          y2="-0.921059"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TwitterXIcon;
