import React from "react";

const SearchSmIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="stroke"
    >
      <path
        d="M8.22225 14.0002C11.7201 14.0002 14.5556 11.1646 14.5556 7.66683C14.5556 4.16903 11.7201 1.3335 8.22225 1.3335C4.72445 1.3335 1.88892 4.16903 1.88892 7.66683C1.88892 11.1646 4.72445 14.0002 8.22225 14.0002Z"
        stroke="url(#paint0_linear_7696_15095)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2222 14.6668L13.8889 13.3335"
        stroke="url(#paint1_linear_7696_15095)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7696_15095"
          x1="16.8343"
          y1="10.6675"
          x2="4.60343"
          y2="-0.31031"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7696_15095"
          x1="15.4621"
          y1="14.316"
          x2="14.1747"
          y2="13.1605"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SearchSmIcon;
