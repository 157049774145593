import axios from "axios";
import { LocalStorages } from "../localStorages";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

axiosInstance.interceptors.request.use((config: any) => {
  const accessToken = LocalStorages.getAccessToken();
  config.headers["Authorization"] = `Bearer ${accessToken}`;
  return config;
});

export const axiosInstanceWithoutAuthToken = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

export default axiosInstance;
