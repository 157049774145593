import React from "react";

const SidebarInfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="fill"
    >
      <path
        d="M12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C18.6168 24 24 18.6168 24 12C24 5.3832 18.6168 0 12 0ZM12 4.92C12.3085 4.92 12.6101 5.01149 12.8667 5.18291C13.1232 5.35432 13.3232 5.59796 13.4413 5.88301C13.5593 6.16807 13.5902 6.48173 13.53 6.78434C13.4698 7.08695 13.3213 7.36492 13.1031 7.58309C12.8849 7.80126 12.607 7.94983 12.3043 8.01002C12.0017 8.07022 11.6881 8.03932 11.403 7.92125C11.118 7.80318 10.8743 7.60323 10.7029 7.34669C10.5315 7.09015 10.44 6.78854 10.44 6.48C10.44 6.06626 10.6044 5.66947 10.8969 5.37691C11.1895 5.08436 11.5863 4.92 12 4.92ZM15.84 18.48H8.64V16.56H11.28V11.28H9.36V9.36H13.2V16.56H15.84V18.48Z"
        fill="url(#paint0_linear_9358_118236)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_118236"
          x1="28.3176"
          y1="17.6856"
          x2="5.14328"
          y2="-3.11458"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SidebarInfoIcon;
