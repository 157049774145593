import { all } from "redux-saga/effects";
import { authReducer, authSaga } from "./authSlice";
import {
  cexTransactionsReducer,
  cexTransactionsSaga,
} from "./cexTransactionsSlice";
import {
  dexTransactionsReducer,
  dexTransactionsSaga,
} from "./dexTransactionsSlice";
import { networkStatsReducer, networkStatsSaga } from "./networkStatsSlice";
import {
  newWalletAccumulationsReducer,
  newWalletAccumulationsSaga,
} from "./newWalletAccumulationsSlice";
import { perpDexReducer, perpDexSaga } from "./perpDexSlice";
import { perpDexTraderReducer, perpDexTraderSaga } from "./perpDexTraderSlice";
import { sidebarReducer } from "./sidebarSlice";
import {
  smartAccumulationsReducer,
  smartAccumulationsSaga,
} from "./smartAccumulationsSlice";
import { smartHoldingsReducer, smartHoldingsSaga } from "./smartHoldingsSlice";
import { smartWhalesReducer, smartWhalesSaga } from "./smartWhalesSlice";
import { snackbarReducer } from "./snackbarSlice";
import { statisticsReducer, statisticsSaga } from "./statisticsSlice";
import { themeReducer } from "./themeSlice";
import { tokenInfoReducer, tokenInfoSaga } from "./tokenInfoSlice";
import {
  totalAccumulationsReducer,
  totalAccumulationsSaga,
} from "./totalAccumulationsSlice";
import { walletReducer, walletSaga } from "./walletSlice";
import { watchlistReducer, watchlistSaga } from "./watchlistSlice";
import { whaleTradesReducer, whaleTradesSaga } from "./whaleTradesSlice";

export const rootReducer = {
  auth: authReducer,
  theme: themeReducer,
  snackbar: snackbarReducer,
  sidebar: sidebarReducer,
  networkStats: networkStatsReducer,
  cexTransactions: cexTransactionsReducer,
  dexTransactions: dexTransactionsReducer,
  statistics: statisticsReducer,
  tokenInfo: tokenInfoReducer,
  watchlist: watchlistReducer,
  smartWhales: smartWhalesReducer,
  smartHoldings: smartHoldingsReducer,
  totalAccumulations: totalAccumulationsReducer,
  smartAccumulations: smartAccumulationsReducer,
  newWalletAccumulations: newWalletAccumulationsReducer,
  wallet: walletReducer,
  whaleTrades: whaleTradesReducer,
  perpDex: perpDexReducer,
  perpDexTrader: perpDexTraderReducer,
};

export function* rootSaga() {
  yield all([
    authSaga(),
    networkStatsSaga(),
    cexTransactionsSaga(),
    dexTransactionsSaga(),
    statisticsSaga(),
    tokenInfoSaga(),
    watchlistSaga(),
    smartWhalesSaga(),
    smartHoldingsSaga(),
    totalAccumulationsSaga(),
    smartAccumulationsSaga(),
    newWalletAccumulationsSaga(),
    walletSaga(),
    whaleTradesSaga(),
    perpDexSaga(),
    perpDexTraderSaga(),
  ]);
}
