import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, takeLatest, all, put } from "redux-saga/effects";
import { IWhaleTradesQuery, IWhaleTrades } from "../../@types/redux";
import { StatisticsAPI } from "../../utils/api/StatisticsAPI";

export interface IWhaleTradesState {
  isRefreshing: boolean;
  loading: boolean;
  total: number;
  transactions: [];
  query: IWhaleTradesQuery;
}

export const whaleTradesDefaultQuery = {
  chain: "",
  threshold: "50000",
  page: 1,
  page_size: 50,
  total_limit: 10000,
  exclude_ncg_tokens: true,
};

const initialState: IWhaleTradesState = {
  isRefreshing: false,
  loading: false,
  total: 0,
  transactions: [],
  query: whaleTradesDefaultQuery,
};

const WhaleTransactionsSlice = createSlice({
  name: "whaleTransactions",
  initialState,
  reducers: {
    getList: (state, _: PayloadAction<IWhaleTradesQuery>) => {
      if (!state.isRefreshing) {
        state.loading = true;
        state.total = 0;
        state.transactions = [];
      }
    },

    setList: (state, action: PayloadAction<IWhaleTrades>) => {
      state.isRefreshing = false;
      state.loading = false;
      state.total = action.payload.total;
      state.transactions = action.payload.transactions;
    },

    setQuery: (state, action: PayloadAction<IWhaleTradesQuery>) => {
      state.query.page = action.payload.page;
      state.query.page_size = action.payload.page_size;
      state.query.chain = action.payload.chain;
      state.query.threshold = action.payload.threshold;
      state.query.total_limit = action.payload.total_limit;
      state.query.exclude_ncg_tokens = action.payload.exclude_ncg_tokens;
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
      state.query = {
        ...whaleTradesDefaultQuery,
        threshold: state.query.threshold,
        exclude_ncg_tokens: state.query.exclude_ncg_tokens,
      };
    },
  },
});

const whaleTradesActions = WhaleTransactionsSlice.actions;
const whaleTradesReducer = WhaleTransactionsSlice.reducer;

export { whaleTradesActions, whaleTradesReducer };

// Sagas

function* getWhaleTrades(action: any): any {
  const res = yield call(StatisticsAPI.getWhaleDexTransactions, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      whaleTradesActions.setList({
        transactions: res.data.results,
        total: res.data.total_count,
      })
    );
  } else {
    yield put(
      whaleTradesActions.setList({
        transactions: [],
        total: 0,
      })
    );
  }
}

export function* whaleTradesSaga() {
  yield all([takeLatest(whaleTradesActions.getList, getWhaleTrades)]);
}
