import React from "react";

function DiscoveryAlphaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      className="fill"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.7587 5.5H12.2413C13.0463 5.49999 13.7106 5.49998 14.2518 5.54419C14.8139 5.59012 15.3306 5.68868 15.816 5.93597C16.5686 6.31947 17.1805 6.93139 17.564 7.68404C17.8113 8.16937 17.9099 8.68608 17.9558 9.24817C18 9.78936 18 10.4537 18 11.2587V15.7413C18 16.5463 18 17.2106 17.9558 17.7518C17.9099 18.3139 17.8113 18.8306 17.564 19.316C17.1805 20.0686 16.5686 20.6805 15.816 21.064C15.3306 21.3113 14.8139 21.4099 14.2518 21.4558C13.7106 21.5 13.0463 21.5 12.2413 21.5H5.75873C4.95374 21.5 4.28938 21.5 3.74817 21.4558C3.18608 21.4099 2.66937 21.3113 2.18404 21.064C1.43139 20.6805 0.819468 20.0686 0.435975 19.316C0.188684 18.8306 0.0901197 18.3139 0.0441945 17.7518C-2.28137e-05 17.2106 -1.23241e-05 16.5463 4.31291e-07 15.7413V11.2587C-1.23241e-05 10.4537 -2.28137e-05 9.78937 0.0441945 9.24817C0.0901197 8.68608 0.188684 8.16937 0.435975 7.68404C0.819468 6.93139 1.43139 6.31947 2.18404 5.93597C2.66937 5.68868 3.18608 5.59012 3.74817 5.54419C4.28937 5.49998 4.95373 5.49999 5.7587 5.5ZM3.91104 7.53755C3.47262 7.57337 3.24842 7.6383 3.09202 7.71799C2.7157 7.90973 2.40973 8.21569 2.21799 8.59202C2.1383 8.74842 2.07337 8.97262 2.03755 9.41104C2.00078 9.86113 2 10.4434 2 11.3V15.7C2 16.5566 2.00078 17.1389 2.03755 17.589C2.07337 18.0274 2.1383 18.2516 2.21799 18.408C2.40973 18.7843 2.7157 19.0903 3.09202 19.282C3.24842 19.3617 3.47262 19.4266 3.91104 19.4624C4.36113 19.4992 4.94342 19.5 5.8 19.5H12.2C13.0566 19.5 13.6389 19.4992 14.089 19.4624C14.5274 19.4266 14.7516 19.3617 14.908 19.282C15.2843 19.0903 15.5903 18.7843 15.782 18.408C15.8617 18.2516 15.9266 18.0274 15.9624 17.589C15.9992 17.1389 16 16.5566 16 15.7V11.3C16 10.4434 15.9992 9.86113 15.9624 9.41104C15.9266 8.97262 15.8617 8.74842 15.782 8.59202C15.5903 8.21569 15.2843 7.90973 14.908 7.71799C14.7516 7.6383 14.5274 7.57337 14.089 7.53755C13.6389 7.50078 13.0566 7.5 12.2 7.5H5.8C4.94342 7.5 4.36113 7.50078 3.91104 7.53755Z"
        fill="url(#paint0_linear_9358_118191)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00004 2.93L7 3.50006C6.99997 4.05235 6.55222 4.50003 5.99994 4.5C5.44766 4.49997 4.99997 4.05222 5 3.49994L5.00006 2.49994C5.00008 2.24201 5.09975 1.99407 5.27826 1.8079C5.52078 1.55498 5.88254 1.18715 6.51489 0.912789C7.12646 0.647442 7.91511 0.5 9.00005 0.5C10.085 0.5 10.8736 0.647454 11.4852 0.912808C12.1175 1.18718 12.4793 1.555 12.7218 1.80788C12.9003 1.99406 13 2.24204 13 2.5V3.5C13 4.05228 12.5523 4.5 12 4.5C11.4477 4.5 11 4.05228 11 3.5V2.92998C10.9115 2.86092 10.8144 2.80192 10.6891 2.74754C10.4093 2.62611 9.91506 2.5 9.00005 2.5C8.08502 2.5 7.59082 2.62611 7.31095 2.74754C7.18561 2.80192 7.08855 2.86092 7.00004 2.93Z"
        fill="url(#paint1_linear_9358_118191)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2 14.9C5.53137 14.4582 6.15817 14.3686 6.6 14.7C7.40663 15.305 8.18856 15.5004 8.99946 15.5C9.81035 15.4996 10.594 15.3045 11.4 14.7C11.8418 14.3686 12.4686 14.4582 12.8 14.9C13.1314 15.3418 13.0418 15.9686 12.6 16.3C11.406 17.1955 10.1879 17.4994 9.00054 17.5C7.80971 17.5006 6.59337 17.195 5.4 16.3C4.95817 15.9686 4.86863 15.3418 5.2 14.9Z"
        fill="url(#paint2_linear_9358_118191)"
      />
      <path
        d="M4 12C4 11.1716 4.67157 10.5 5.5 10.5C6.32843 10.5 7 11.1716 7 12C7 12.8284 6.32843 13.5 5.5 13.5C4.67157 13.5 4 12.8284 4 12Z"
        fill="url(#paint3_linear_9358_118191)"
      />
      <path
        d="M11 12C11 11.1716 11.6716 10.5 12.5 10.5C13.3284 10.5 14 11.1716 14 12C14 12.8284 13.3284 13.5 12.5 13.5C11.6716 13.5 11 12.8284 11 12Z"
        fill="url(#paint4_linear_9358_118191)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_118191"
          x1="21.2382"
          y1="15.9749"
          x2="1.52386"
          y2="0.80804"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9358_118191"
          x1="21.2382"
          y1="15.9749"
          x2="1.52386"
          y2="0.80804"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9358_118191"
          x1="21.2382"
          y1="15.9749"
          x2="1.52386"
          y2="0.80804"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9358_118191"
          x1="21.2382"
          y1="15.9749"
          x2="1.52386"
          y2="0.80804"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9358_118191"
          x1="21.2382"
          y1="15.9749"
          x2="1.52386"
          y2="0.80804"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default DiscoveryAlphaIcon;
