import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { AuthHelpers } from "../../helpers/auth";
import { sidebarActions } from "../../redux/features/sidebarSlice";
import { themeActions } from "../../redux/features/themeSlice";
import { sidebarStateSelector } from "../../redux/selectors/sidebarSelectors";
import { themeStateSelector } from "../../redux/selectors/themeSelectors";
import { Chains, SubScrType, ThemeTypes } from "../../utils/constants";
import { LocalStorages } from "../../utils/localStorages";
import { AppRoutes } from "../../utils/routes";
import DexToolkitIcon from "../Icons/DexToolkitIcon";
import DiscoveryAlphaIcon from "../Icons/DiscoveryAlphaIcon";
import DropdownIcon from "../Icons/DropdownIcon";
import LeaderBoardIcon from "../Icons/LeaderBoardIcon";
import LiveDexMasterIcon from "../Icons/LiveDexMasterIcon";
import LockIcon from "../Icons/LockIcon";
import PerpDexDataIcon from "../Icons/PerpDexDataIcon";
import ProfileIcon from "../Icons/ProfileIcon";
import SidebarCloseIcon from "../Icons/SidebarCloseIcon";
import SidebarHelpIcon from "../Icons/SidebarHelpIcon";
import SidebarInfoIcon from "../Icons/SidebarInfoIcon";
import SidebarOnChainIcon from "../Icons/SidebarOnChainIcon";
import SidebarResearchIcon from "../Icons/SidebarResearchIcon";
import ThemeMoonIcon from "../Icons/ThemeMoonIcon";
import ThemeSunIcon from "../Icons/ThemeSunIcon";
import UnlockIcon from "../Icons/UnlockIcon";
import WatchlistIcon2 from "../Icons/WatchlistIcon2";
import ChainSelector from "./ChainSelector";

import "./scss/index.scss";

interface IMenuLink {
  icon?: ReactNode;
  name: string;
  href: string;
  links?: [string];
  supportChains?: [string];
  privilege?: number;
  isDisabled?: boolean;
}

const Sidebar = () => {
  const location = useLocation();
  const isLoggedIn = AuthHelpers.isUserLoggedIn();
  const subScrType = isLoggedIn ? LocalStorages.getSubScrType() : 0;
  const dispatch = useDispatch();
  const {
    isLocked,
    chain,
    isDexExpanded,
    isPerpDexDataExpanded,
    isDiscoveryExpanded,
    isOpened,
  } = useSelector(sidebarStateSelector);
  const themeState = useSelector(themeStateSelector);
  const quickAccessLinks = [
    // {
    //   name: "Main Dashboard",
    //   href: AppRoutes.dashboard,
    //   icon: <HomeIcon />,
    //   isPremium: true,
    // },
    {
      name: "Home",
      href: AppRoutes.home,
      icon: <LiveDexMasterIcon />,
    },
    {
      name: "My Profile",
      href: AppRoutes.profile,
      icon: <ProfileIcon />,
      privilege: SubScrType.Starter,
    },
    {
      name: "Leaderboard",
      href: AppRoutes.leaderBoard,
      icon: <LeaderBoardIcon />,
      privilege: SubScrType.Starter,
    },
    {
      name: "Watchlist",
      href: AppRoutes.watchlist,
      icon: <WatchlistIcon2 />,
      privilege: SubScrType.Starter,
    },
  ] as IMenuLink[];

  const dexToolkitLinks = [
    {
      name: "Live DEX Master",
      href: AppRoutes.liveDexMaster,
      links: [AppRoutes.home],
    },
    {
      name: "Token Genius",
      href: AppRoutes.getTokenGeniusIndexRoute(),
      links: [
        AppRoutes.tokenGeniusIndex,
        AppRoutes.getTokenGeniusRouteWithoutRefCode(),
      ],
      supportChains: [
        Chains.All,
        Chains.Ethereum,
        Chains.Arbitrum,
        Chains.Binance,
        Chains.Polygon,
        Chains.Optimism,
        Chains.Avalanche,
        Chains.Base,
        Chains.Cronos,
      ],
    },
    {
      name: "Wallet Deep Explorer",
      href: AppRoutes.getWalletIndexRoute(),
      links: [
        AppRoutes.walletIndex,
        AppRoutes.getWalletProfileRouteWithoutRefCode(),
      ],
      supportChains: [
        Chains.Ethereum,
        Chains.Arbitrum,
        Chains.Binance,
        Chains.Polygon,
        Chains.Optimism,
        Chains.Avalanche,
        Chains.Base,
        Chains.Cronos,
      ],
    },
    {
      name: "Whale DEX Stream",
      href: AppRoutes.whaleTrades,
      privilege: SubScrType.Starter,
    },
    {
      name: "On-Chain AI",
      href: AppRoutes.onchainAI,
      privilege: SubScrType.Plus,
      supportChains: [Chains.Ethereum],
    },
  ] as IMenuLink[];

  const perpDexDataLinks = [
    {
      name: "Perp DEX Outlook",
      href: AppRoutes.perpGmxData,
      supportChains: [Chains.Ethereum],
      privilege: SubScrType.Plus,
    },
    {
      name: "Perp Trader Data",
      href: AppRoutes.perpGmxTraderData,
      supportChains: [Chains.Ethereum],
      privilege: SubScrType.Plus,
    },
    {
      name: "DEX Open Trades",
      href: AppRoutes.perpOpenTrades,
      supportChains: [Chains.Ethereum],
      privilege: SubScrType.Plus,
    },
  ] as IMenuLink[];

  const discoveryAlphaLinks = [
    {
      name: "A.I Smart Money",
      href: "/ai-smart",
      isDisabled: true,
    },
    {
      name: "Top 100 Traders",
      href: "/top25",
      isDisabled: true,
    },
    {
      name: "Narrative Pulse",
      href: AppRoutes.narrativePulse,
      privilege: SubScrType.Plus,
    },
    {
      name: "Smart Money DEX Stream",
      href: AppRoutes.smartDexStream,
      privilege: SubScrType.Plus,
    },
    {
      name: "Smart Money Holdings",
      href: AppRoutes.smartHoldings,
      supportChains: [
        Chains.Ethereum,
        Chains.Arbitrum,
        Chains.Binance,
        Chains.Polygon,
        Chains.Avalanche,
        Chains.Base,
        Chains.Cronos,
      ],
      privilege: SubScrType.Plus,
    },
    {
      name: "DEX Accumulations",
      href: AppRoutes.dexAccumulations,
      supportChains: [
        Chains.Ethereum,
        Chains.Arbitrum,
        Chains.Binance,
        Chains.Polygon,
        Chains.Optimism,
        Chains.Avalanche,
        Chains.Base,
        Chains.Cronos,
      ],
      privilege: SubScrType.Plus,
    },
    {
      name: "CEX Netflows",
      href: AppRoutes.cexNetTransactions,
      supportChains: [
        Chains.Ethereum,
        Chains.Arbitrum,
        Chains.Binance,
        Chains.Polygon,
        Chains.Optimism,
        Chains.Avalanche,
        Chains.Base,
        Chains.Cronos,
      ],
      privilege: SubScrType.Pro,
    },
  ] as IMenuLink[];

  const isMenuActive = (item: IMenuLink) => {
    const pathname = location.pathname;
    if (item.href === AppRoutes.home)
      return pathname === item.href || pathname === AppRoutes.liveDexMaster;
    if (pathname.includes(item.href)) return true;
    if (!item.links) return false;
    for (let i = 0; i < item.links.length; i++) {
      if (item.links[i] === AppRoutes.home) return pathname === item.links[i];
      if (pathname.includes(item.links[i])) return true;
    }
    return false;
  };

  const isActiveDexToolkit = () => {
    for (let i = 0; i < dexToolkitLinks.length; i++)
      if (isMenuActive(dexToolkitLinks[i])) return true;
    return false;
  };

  const isActivePerpDexData = () => {
    for (let i = 0; i < perpDexDataLinks.length; i++)
      if (isMenuActive(perpDexDataLinks[i])) return true;
    return false;
  };

  const isActiveDiscoveryAlpha = () => {
    for (let i = 0; i < discoveryAlphaLinks.length; i++)
      if (isMenuActive(discoveryAlphaLinks[i])) return true;
    return false;
  };

  const handleChangeTheme = () => {
    const type = themeState.theme === "dark" ? "light" : "dark";
    dispatch(themeActions.setTheme({ theme: type }));
  };

  const handleToggleLock = () => {
    dispatch(sidebarActions.toggleLock({}));
  };

  const handleTogglePerpDexData = () => {
    dispatch(sidebarActions.togglePerpDexData({}));
  };

  const handleToggleDexToolkit = () => {
    dispatch(sidebarActions.toggleDex({}));
  };

  const handleToggleDiscoveryAlpha = () => {
    dispatch(sidebarActions.toggleDiscovery({}));
  };

  useEffect(() => {
    if (isActiveDexToolkit()) dispatch(sidebarActions.expandDex({}));
    if (isActivePerpDexData()) dispatch(sidebarActions.expandPerpDexData({}));
    if (isActiveDiscoveryAlpha()) dispatch(sidebarActions.expandDiscovery({}));
    dispatch(sidebarActions.closeSidebar({}));
  }, []);

  useEffect(() => {
    document.body.setAttribute("data-theme", themeState.theme);
  }, [themeState]);

  const handleCloseSidebar = () => {
    dispatch(sidebarActions.closeSidebar({}));
  };

  return (
    <>
      {isOpened && (
        <div className="sidebar-backdrop" onClick={handleCloseSidebar}></div>
      )}
      <div
        className={`sidebar-container ${isOpened ? "opened" : ""} ${
          isLocked ? "locked" : ""
        }`}
      >
        <div className="chain-selector-container">
          <ChainSelector />
          <button className="mobile" onClick={handleCloseSidebar}>
            <SidebarCloseIcon />
          </button>
          <div className="sidebar-toggler desktop" onClick={handleToggleLock}>
            {isLocked ? <LockIcon /> : <UnlockIcon />}
          </div>
        </div>

        <div className="menu-container">
          <div className="quick-access-links">
            <div className="title dm-sans-600">QUICK ACCESS</div>
            {quickAccessLinks.map((item) => (
              <Link
                to={AppRoutes.getExactRoute(item.href)}
                key={item.name}
                className={`link ${isMenuActive(item) ? "active" : ""} ${
                  item.privilege === SubScrType.Pro && "pro"
                } ${item.privilege === SubScrType.Plus && "plus"} ${
                  item.supportChains &&
                  !item.supportChains?.includes(chain) &&
                  "disabled"
                }`}
              >
                <div className="d-flex">{item.icon}</div>{" "}
                <p className="name dm-sans-500 text-primary-main">
                  {item.name}
                </p>
              </Link>
            ))}
          </div>
          <div
            className={`dex-toolkit-links ${isDexExpanded ? "show-child" : ""}`}
          >
            <div className="title" onClick={handleToggleDexToolkit}>
              <div className="d-flex">
                <DexToolkitIcon />
              </div>
              <div className="name dm-sans-500 text-primary-main">
                On-Chain Tool Suite
              </div>
              <DropdownIcon />
            </div>
            <div className="links dm-sans-400">
              {dexToolkitLinks.map((item) => (
                <Link
                  to={AppRoutes.getExactRoute(item.href)}
                  key={item.name}
                  className={`${
                    isMenuActive(item) ? "active text-primary-main" : ""
                  } ${item.isDisabled && "disabled"} ${
                    item.supportChains &&
                    !item.supportChains?.includes(chain) &&
                    "disabled"
                  } ${item.privilege === SubScrType.Pro && "pro"} ${
                    item.privilege === SubScrType.Plus && "plus"
                  }`}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          <div
            className={`perp-dex-data-links ${
              isPerpDexDataExpanded ? "show-child" : ""
            }`}
          >
            <div className={"title plus"} onClick={handleTogglePerpDexData}>
              <div className="d-flex">
                <PerpDexDataIcon />
              </div>
              <div className="name dm-sans-500 text-primary-main">
                Perp DEX Data
              </div>
              <DropdownIcon />
            </div>
            <div className="links dm-sans-400">
              {perpDexDataLinks.map((item) => (
                <Link
                  to={AppRoutes.getExactRoute(item.href)}
                  key={item.name}
                  className={`${item.isDisabled ? "disabled" : ""} ${
                    isMenuActive(item) ? "active text-primary-main" : ""
                  } ${
                    item.supportChains &&
                    !item.supportChains?.includes(chain) &&
                    "disabled"
                  }`}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          {discoveryAlphaLinks.length ? (
            <div
              className={`discovery-alpha-links ${
                isDiscoveryExpanded ? "show-child" : ""
              }`}
            >
              <div
                className={"title plus"}
                onClick={handleToggleDiscoveryAlpha}
              >
                <div className="d-flex">
                  <DiscoveryAlphaIcon />
                </div>
                <div className="name dm-sans-500 text-primary-main">
                  Discovery Alpha
                </div>
                <DropdownIcon />
              </div>
              <div className="links dm-sans-400">
                {discoveryAlphaLinks.map((item) => (
                  <Link
                    to={AppRoutes.getExactRoute(item.href)}
                    key={item.name}
                    className={`${item.isDisabled ? "disabled" : ""} ${
                      isMenuActive(item) ? "active text-primary-main" : ""
                    } ${
                      item.supportChains &&
                      !item.supportChains?.includes(chain) &&
                      "disabled"
                    } ${item.privilege === SubScrType.Pro && "pro"}`}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          ) : null}

          <div className="link-container">
            <Link
              to="https://docs.alphanomics.io/help"
              className="sidebar-link"
              target="_blank"
            >
              <SidebarHelpIcon />
            </Link>
            <Link
              to="https://alphanomics.io/research"
              className="sidebar-link"
              target="_blank"
            >
              <SidebarResearchIcon />
            </Link>
            <Link
              to="https://alphanomics.io/onchain101"
              className="sidebar-link"
              target="_blank"
            >
              <SidebarOnChainIcon />
            </Link>
            <Link
              to="https://alphanomics.io/"
              className="sidebar-link"
              target="_blank"
            >
              <SidebarInfoIcon />
            </Link>
          </div>

          <div className="theme-selector">
            <div className="mobile-tablet">
              {isLoggedIn && (
                <>
                  <Link
                    to={AppRoutes.getExactRoute(AppRoutes.smartAlert)}
                    className={`header-links plus ${
                      location.pathname === AppRoutes.smartAlert
                        ? "text-primary-main"
                        : ""
                    } ${subScrType < SubScrType.Plus && "disabled"}`}
                  >
                    Smart Alerts
                  </Link>
                </>
              )}
            </div>

            <button onClick={() => handleChangeTheme()}>
              {themeState.theme === ThemeTypes.dark ? (
                <>
                  <ThemeSunIcon />
                  <a>Switch to Light mode</a>
                </>
              ) : (
                <>
                  <ThemeMoonIcon />
                  <a>Switch to Dark mode</a>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
