import { INetworkInfoQuery } from "../../@types/redux";
import { Chains } from "../constants";
import { BaseAPI } from "./BaseAPI";

export class NetworkInfoAPI {
  getNetworkInfo = async ({ chain }: INetworkInfoQuery) => {
    return BaseAPI.callAPI({
      url: `/network/info/`,
      params: { chain: chain === Chains.All ? null : chain },
      needToken: false,
    });
  };
}

export default new NetworkInfoAPI();
