import { ICexAdvancedFilterQuery } from "../../@types/redux";
import { BaseAPI } from "./BaseAPI";

interface ICexFlows {
  page: number;
  page_size: number;
  direction: string;
  ordering: string;
  chain: string;
}

interface ICexFlowTransactions {
  chain: string;
  page: number;
  page_size: number;
  direction: string;
  total_count: boolean;
  advanced_filter: ICexAdvancedFilterQuery;
  flg_summary: boolean;
}

interface ICexStatistics {
  chain: string;
  num_hrs: number;
}

export class CexFlowsAPI {
  static getFlows = async ({
    page,
    page_size,
    direction,
    ordering = "",
    chain,
  }: ICexFlows) => {
    return BaseAPI.callAPI({
      url: "/cex/flows/",
      params: {
        chain,
        page,
        page_size,
        direction,
        ordering,
      },
    });
  };

  static getFlowTransactions = async ({
    chain,
    page,
    page_size,
    direction,
    total_count,
    advanced_filter,
    flg_summary,
  }: ICexFlowTransactions) => {
    return BaseAPI.callAPI({
      url: "/cex/transactions/",
      params: {
        chain,
        page,
        page_size,
        direction,
        total_count,
        flg_summary,
        from_ts: advanced_filter.from_ts,
        to_ts: advanced_filter.to_ts,
        cex_platform:
          advanced_filter.cex_platform === "all"
            ? null
            : advanced_filter.cex_platform,
        wallet_address: advanced_filter.wallet_address,
        token_address:
          advanced_filter.token_addresses.length > 0
            ? advanced_filter.token_addresses[0]
            : "",
        min_token_amount: advanced_filter.min_token_amount,
        max_token_amount: advanced_filter.max_token_amount,
        min_amount_usd: advanced_filter.min_amount_usd,
        max_amount_usd: advanced_filter.max_amount_usd,
      },
    });
  };

  static getCexPlatformsList = async () => {
    return BaseAPI.callAPI({
      url: "/cex/platforms/",
    });
  };

  static getTokenStatistics = async ({ chain, num_hrs }: ICexStatistics) => {
    return BaseAPI.callAPI({
      url: "/cex/token_statistics/",
      params: {
        chain,
        num_hrs,
      },
    });
  };

  static getTxnStatistics = async ({ chain, num_hrs }: ICexStatistics) => {
    return BaseAPI.callAPI({
      url: "/cex/txn_statistics/",
      params: {
        chain,
        num_hrs,
      },
    });
  };
}
