import jwtDecode from "jwt-decode";
import { RootState } from "..";

export const authStateSelector = (state: RootState) => state.auth;

export const isLoggedInSelector = (state: RootState) => {
  const accessToken = state.auth.data.access_token;
  if (accessToken) {
    try {
      const decodedToken = jwtDecode(accessToken) as any;
      const expiresTime = (decodedToken?.exp || 0) * 1000;
      return expiresTime > new Date().getTime();
    } catch (e) {
      return false;
    }
  }
  return false;
};
