import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITheme } from "../../@types/redux";
import { ThemeTypes } from "../../utils/constants";
import { LocalStorages } from "../../utils/localStorages";

const initialState = {
  theme: ThemeTypes.dark,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<ITheme>) => {
      LocalStorages.setTheme(action.payload.theme);
      state.theme = action.payload.theme;
    },
  },
});

export const themeActions = themeSlice.actions;
export const themeReducer = themeSlice.reducer;
