import React from "react";

const SidebarHelpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="stroke"
    >
      <path
        d="M12.5 13L10 17H14L11.5 21"
        stroke="url(#paint0_linear_9358_118226)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 13.5V13C4 8.02944 7.58172 4 12 4C16.4183 4 20 8.02944 20 13V13.5"
        stroke="url(#paint1_linear_9358_118226)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 17.4387V15.5618C2 14.6441 2.62459 13.8441 3.51493 13.6215L4 13.5002L5.25448 13.1866C5.63317 13.092 6 13.3784 6 13.7687V19.2318C6 19.6221 5.63317 19.9085 5.25448 19.8139L3.51493 19.379C2.62459 19.1564 2 18.3564 2 17.4387Z"
        stroke="url(#paint2_linear_9358_118226)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 17.4387V15.5618C22 14.6441 21.3754 13.8441 20.4851 13.6215L20 13.5002L18.7455 13.1866C18.3668 13.092 18 13.3784 18 13.7687V19.2318C18 19.6221 18.3668 19.9085 18.7455 19.8139L20.4851 19.379C21.3754 19.1564 22 18.3564 22 17.4387Z"
        stroke="url(#paint3_linear_9358_118226)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_118226"
          x1="14.7196"
          y1="18.8952"
          x2="8.91477"
          y2="16.2901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9358_118226"
          x1="22.8784"
          y1="11.0005"
          x2="14.3869"
          y2="-1.83577"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9358_118226"
          x1="6.71961"
          y1="18.0789"
          x2="1.31462"
          y2="15.1669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9358_118226"
          x1="22.7196"
          y1="18.0789"
          x2="17.3146"
          y2="15.1669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SidebarHelpIcon;
