import React from "react";

function DexToolkitIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="stroke"
    >
      <g clipPath="url(#clip0_9358_118130)">
        <path
          d="M6 17.6L4 16.5V14"
          stroke="url(#paint0_linear_9358_118130)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 9.9999V7.4999L6 6.3999"
          stroke="url(#paint1_linear_9358_118130)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 4.1L12 3L14 4.1"
          stroke="url(#paint2_linear_9358_118130)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 6.3999L20 7.4999V9.9999"
          stroke="url(#paint3_linear_9358_118130)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 14V16.5L18 17.62"
          stroke="url(#paint4_linear_9358_118130)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 19.8999L12 20.9999L10 19.8999"
          stroke="url(#paint5_linear_9358_118130)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 11.9999L14 10.8999"
          stroke="url(#paint6_linear_9358_118130)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 8.6L20 7.5"
          stroke="url(#paint7_linear_9358_118130)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 12V14.5"
          stroke="url(#paint8_linear_9358_118130)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 18.5V21"
          stroke="url(#paint9_linear_9358_118130)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 11.9999L10 10.8799"
          stroke="url(#paint10_linear_9358_118130)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 8.6L4 7.5"
          stroke="url(#paint11_linear_9358_118130)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_9358_118130"
          x1="6.3598"
          y1="16.6528"
          x2="3.5672"
          y2="15.2603"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9358_118130"
          x1="6.3598"
          y1="9.05274"
          x2="3.5672"
          y2="7.66024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9358_118130"
          x1="14.7196"
          y1="3.81059"
          x2="14.1211"
          y2="1.85722"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9358_118130"
          x1="20.3598"
          y1="9.05274"
          x2="17.5672"
          y2="7.66024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9358_118130"
          x1="20.3598"
          y1="16.6676"
          x2="17.5611"
          y2="15.2797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_9358_118130"
          x1="14.7196"
          y1="20.7105"
          x2="14.1211"
          y2="18.7571"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_9358_118130"
          x1="14.3598"
          y1="11.7105"
          x2="13.4079"
          y2="10.1571"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_9358_118130"
          x1="20.3598"
          y1="8.31059"
          x2="19.4079"
          y2="6.75716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_9358_118130"
          x1="13.1799"
          y1="13.8422"
          x2="11.6355"
          y2="13.2878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_9358_118130"
          x1="13.1799"
          y1="20.3422"
          x2="11.6355"
          y2="19.7878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_9358_118130"
          x1="12.3598"
          y1="11.7052"
          x2="11.3828"
          y2="10.1392"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_9358_118130"
          x1="6.3598"
          y1="8.31059"
          x2="5.40789"
          y2="6.75716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <clipPath id="clip0_9358_118130">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DexToolkitIcon;
