import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ISmartHoldings,
  ISmartHoldingsMetric,
  ISmartHoldingsQuery,
} from "../../@types/redux";
import { SmartHoldingsAPI } from "../../utils/api/SmartHoldingsAPI";

interface ISmartHoldingsState {
  isRefreshing: boolean;
  loading: boolean;
  total_count: number;
  total_page: number;
  results: [];
  query: {
    page_size: number;
    page: number;
    metric: string;
    ordering: string;
  };
}

export const smartHoldingsDefaultQuery = {
  page_size: 25,
  page: 1,
  metric: "All",
  ordering: "",
};

const initialState: ISmartHoldingsState = {
  isRefreshing: false,
  loading: false,
  total_count: 0,
  total_page: 0,
  results: [],
  query: { ...smartHoldingsDefaultQuery },
};

const smartHoldingSlice = createSlice({
  name: "smartHoldings",
  initialState,
  reducers: {
    getList: (state, _: PayloadAction<ISmartHoldingsQuery>) => {
      if (!state.isRefreshing) {
        state.loading = true;
        state.results = [];
      }
    },

    setList: (state, action: PayloadAction<ISmartHoldings>) => {
      state.isRefreshing = false;
      state.loading = false;
      state.total_count = action.payload.total_count;
      state.total_page = action.payload.total_page;
      state.results = action.payload.results;
    },

    setQuery: (state, action: PayloadAction<ISmartHoldingsQuery>) => {
      state.query.page_size = action.payload.page_size;
      state.query.page = action.payload.page;
      state.query.ordering = action.payload.ordering;
      state.query.metric = action.payload.metric;
    },

    setMetric: (state, action: PayloadAction<ISmartHoldingsMetric>) => {
      state.query.metric = action.payload.metric;
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
      state.query = {
        ...smartHoldingsDefaultQuery,
        metric: state.query.metric,
      };
    },
  },
});

const smartHoldingsAction = smartHoldingSlice.actions;
const smartHoldingsReducer = smartHoldingSlice.reducer;

export { smartHoldingsAction, smartHoldingsReducer };

// Sagas

function* getSmartHoldings(action: PayloadAction<ISmartHoldingsQuery>): any {
  const res = yield call(SmartHoldingsAPI.getSmartHoldings as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      smartHoldingsAction.setList({
        ...res.data,
      })
    );
  } else {
    yield put(
      smartHoldingsAction.setList({
        total_count: 0,
        total_page: 0,
        results: [],
      })
    );
  }
}

export function* smartHoldingsSaga() {
  yield all([takeLatest(smartHoldingsAction.getList, getSmartHoldings)]);
}
