import {
  IGetTokenDexTxsParams,
  IGetTokenDexWhaleTxsParams,
} from "../../@types/api";
import { Chains } from "../constants";
import { BaseAPI } from "./BaseAPI";

export class TokenDexTransactionsAPI {
  static getTokenDexTransactions = async ({
    chain,
    page = 1,
    page_size = 25,
    ordering = "",
    total_count = false,
    token_address,
    filter_by = null,
    filter_values = "",
    flg_summary,
    advanced_filter,
  }: IGetTokenDexTxsParams) => {
    return BaseAPI.callAPI({
      url: `token/${token_address}/dex_transactions/`,
      params: {
        chain: chain === Chains.All ? null : chain,
        page,
        page_size,
        ordering,
        total_count,
        filter_by,
        filter_values,
        flg_summary,
        from_ts: advanced_filter.from_ts,
        to_ts: advanced_filter.to_ts,
        trade_type: advanced_filter.apply_filter
          ? advanced_filter.trade_type
          : null,
        trade_token_addresses: advanced_filter.trade_token_addresses,
        min_main_token_amount: advanced_filter.min_main_token_amount,
        max_main_token_amount: advanced_filter.max_main_token_amount,
        min_trade_token_amount: advanced_filter.min_trade_token_amount,
        max_trade_token_amount: advanced_filter.max_trade_token_amount,
        trader_address: advanced_filter.trader_address,
        min_amount_usd: advanced_filter.min_amount_usd,
        max_amount_usd: advanced_filter.max_amount_usd,
        flg_advanced_filtering: advanced_filter.apply_filter,
      },
      needToken: advanced_filter.apply_filter,
    });
  };

  static getTokenDexWhaleTransactions = async ({
    chain,
    page = 1,
    page_size = 25,
    total_count = false,
    token_address,
    threshold,
  }: IGetTokenDexWhaleTxsParams) => {
    return BaseAPI.callAPI({
      url: `token/${token_address}/dex_whale_transactions/`,
      params: {
        chain,
        page,
        page_size,
        total_count,
        threshold_value: threshold,
      },
      needToken: false,
    });
  };

  static getSmartMoneyTransactions = async ({
    chain,
    page = 1,
    page_size = 25,
    total_count = false,
    token_address,
  }: IGetTokenDexWhaleTxsParams) => {
    return BaseAPI.callAPI({
      url: `token/${token_address}/dex_transactions/`,
      params: {
        chain,
        page,
        page_size,
        total_count,
        flg_smart_whales: true,
      },
    });
  };
}
