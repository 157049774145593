import {
  IGetWatchlistTokensParams,
  IGetWatchlistWalletsParams,
  ICheckWatchWalletParams,
  IWatchWalletParams,
  IUnWatchWalletParams,
  IWatchTokenParams,
} from "../../@types/api";
import { BaseAPI } from "./BaseAPI";

export class WatchlistAPI {
  static checkWatchWallet = async ({
    chain,
    wallet_address,
  }: ICheckWatchWalletParams) => {
    return BaseAPI.callAPI({
      url: `accounts/watchlist/${wallet_address}/check_watch_wallet/`,
      params: {
        chain,
      },
    });
  };

  static watchWallet = async ({
    chain,
    wallet_address,
    note,
  }: IWatchWalletParams) => {
    return BaseAPI.callAPI({
      url: `accounts/watchlist/${wallet_address}/watch_wallet/`,
      method: "post",
      data: {
        chain,
        note,
      },
    });
  };

  static updateWalletNote = async ({
    chain,
    wallet_address,
    note,
  }: IWatchWalletParams) => {
    return BaseAPI.callAPI({
      url: `accounts/watchlist/${wallet_address}/update/`,
      method: "post",
      data: {
        chain,
        note,
      },
    });
  };

  static unWatchWallet = async ({
    chain,
    wallet_address,
  }: IUnWatchWalletParams) => {
    return BaseAPI.callAPI({
      url: `accounts/watchlist/${wallet_address}/unwatch_wallet/`,
      method: "post",
      params: {
        chain,
      },
    });
  };

  static checkWatchToken = async ({
    chain,
    token_address,
  }: IWatchTokenParams) => {
    return BaseAPI.callAPI({
      url: `accounts/watchlist/${token_address}/check_watch_token/`,
      params: {
        chain,
      },
    });
  };

  static watchToken = async ({ chain, token_address }: IWatchTokenParams) => {
    return BaseAPI.callAPI({
      url: `accounts/watchlist/${token_address}/watch_token/`,
      method: "post",
      params: {
        chain,
      },
    });
  };

  static unWatchToken = async ({ chain, token_address }: IWatchTokenParams) => {
    return BaseAPI.callAPI({
      url: `accounts/watchlist/${token_address}/unwatch_token/`,
      method: "post",
      params: {
        chain,
      },
    });
  };

  static getWallets = async ({
    page,
    page_size,
  }: IGetWatchlistWalletsParams) => {
    return BaseAPI.callAPI({
      url: "accounts/watchlist/wallets/",
      params: {
        page,
        page_size,
      },
    });
  };

  static getTokens = async ({ page, page_size }: IGetWatchlistTokensParams) => {
    return BaseAPI.callAPI({
      url: "accounts/watchlist/tokens/",
      params: {
        page,
        page_size,
      },
    });
  };
}
