import React from "react";

function LiveDexMasterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="stroke"
    >
      <path
        d="M6.50006 3.39994L3.40006 6.49994C2.10006 7.79994 4.20006 11.9999 8.10006 15.8999C12.0001 19.7999 16.2001 21.8999 17.5001 20.5999L20.6001 17.4999M11.2001 12.7999L8.10006 15.8999M7.70006 8.49994L4.60006 11.5999M12.4001 19.3999L15.5001 16.2999M20.6001 17.4999C19.3001 18.7999 15.1001 16.6999 11.2001 12.7999C7.30006 8.89994 5.20006 4.69994 6.50006 3.39994C7.80006 2.09994 12.0001 4.19994 15.9001 8.09994C19.8001 11.9999 21.9001 16.1999 20.6001 17.4999Z"
        stroke="url(#paint0_linear_9358_118104)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_118104"
          x1="7.74308"
          y1="24.2175"
          x2="23.3169"
          y2="6.86609"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LiveDexMasterIcon;
