import { CircularProgress, ClickAwayListener } from "@mui/material";
import { ethers } from "ethers";
import React, { KeyboardEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { snackbarActions } from "../../redux/features/snackbarSlice";
import { sidebarStateSelector } from "../../redux/selectors/sidebarSelectors";
import { themeStateSelector } from "../../redux/selectors/themeSelectors";
import { TokenInfoAPI } from "../../utils/api/TokenInfoAPI";
import { WalletsAPI } from "../../utils/api/WalletsAPI";
import {
  AddressType,
  Chains,
  NotSupportChains,
  NotSupportChainToast,
  ThemeTypes,
} from "../../utils/constants";
import { LocalStorages } from "../../utils/localStorages";
import { AppRoutes } from "../../utils/routes";
import Token from "../Common/Token";
import SearchIcon from "../Icons/SearchIcon";
import SearchSmIcon from "../Icons/SearchSmIcon";

import "./scss/SearchBar.scss";

interface ISearchBarParams {
  placeholder?: string;
  showBorder?: boolean;
}

interface ISearchData {
  address: string;
  name: string;
  symbol: string;
  logo: string;
  chains: {
    chain: string;
    address: string;
  }[];
}

const SearchBar = ({ placeholder, showBorder = true }: ISearchBarParams) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { chain } = useSelector(sidebarStateSelector);
  const theme = useSelector(themeStateSelector).theme;

  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [allTokenList, setAllTokenList] = useState<ISearchData[]>([]);
  const [savedSearchResults, setSavedSearchResults] = useState<ISearchData[]>(
    []
  );
  const [searchResults, setSearchResults] = useState<ISearchData[]>([]);
  const [keyword, setKeyword] = useState("");

  const getTokenList = async () => {
    setLoading(true);

    const data = LocalStorages.getTokenData();
    if (!data || JSON.parse(data).expires < Date.now()) {
      const res = await TokenInfoAPI.getAllTokens();
      if (res.error) {
        setLoading(false);
        setError(res.error);
        return;
      }
      setAllTokenList(res.data);
      LocalStorages.setTokenData(res.data);
    } else {
      setAllTokenList(JSON.parse(data).data);
    }

    setLoading(false);
  };

  const handleKeyword = async () => {
    const value = keyword.replace(/\s/g, "");
    if (value === "") {
      setError("");
      setSearchResults([]);
      setShowResults(false);
      return;
    }

    setShowResults(true);
    setLoading(true);
    const temp = [];

    if (value.endsWith(".eth")) {
      const tToken = {} as ISearchData;
      const res1 = await WalletsAPI.resolveEns({ ens: value });
      if (!res1.error && res1.data.wallet_address) {
        tToken.address = res1.data.wallet_address;
        temp.push(tToken);
      }
    }

    for (let i = 0; i < allTokenList.length; i++) {
      const token = allTokenList[i];
      if (
        token.symbol.toUpperCase().startsWith(value.toUpperCase()) ||
        token.name.toUpperCase().startsWith(value.toUpperCase())
      ) {
        temp.push(token);
        continue;
      }

      for (let j = 0; j < token.chains.length; j++) {
        if (token.chains[j].address.toUpperCase() === value.toUpperCase()) {
          const tChain = {
            chain: token.chains[j].chain,
            address: token.chains[j].address,
          };
          token.chains = [];
          token.chains.push(tChain);
          temp.push(token);
          break;
        }
      }
    }

    if (temp.length === 0 && ethers.utils.isAddress(value)) {
      const res = await TokenInfoAPI.checkAddress({
        chain,
        token_address: value,
      });

      if (!res.error) {
        const tToken = {} as ISearchData;
        if (res.data.type === AddressType.Wallet) {
          tToken.address = value;
        } else {
          tToken.logo = res.data.logo;
          tToken.name = res.data.name;
          tToken.symbol = res.data.symbol;
          tToken.chains = [];
          tToken.chains.push({
            chain: res.data.chain,
            address: value,
          });
        }
        temp.push(tToken);
      }
    }

    setSearchResults(temp);
    setLoading(false);
  };

  useEffect(() => {
    handleKeyword();
  }, [keyword]);

  const handleOutsideClick = (willSaveResults = false) => {
    let savedResults: ISearchData[] = [];
    if (willSaveResults) savedResults = LocalStorages.getRecentSearches() ?? [];
    setSavedSearchResults(savedResults);

    setShowResults(false);
    setError("");
    setKeyword("");
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleEnter();
  };

  const handleEnter = () => {
    if (searchResults.length === 1 && searchResults[0].address) {
      navigate(
        AppRoutes.getExactRoute(
          AppRoutes.getWalletProfileRoute(
            searchResults[0].address,
            chain === Chains.All ? Chains.Ethereum : chain
          )
        )
      );
      handleOutsideClick(true);
    }
  };

  const handleResultClick = async (result: ISearchData) => {
    if (!result.address) {
      LocalStorages.setRecentSearch(result);

      let i;
      for (i = 0; i < result.chains.length; i++) {
        if (result.chains[i].chain === chain) {
          navigate(
            AppRoutes.getExactRoute(
              AppRoutes.getTokenGeniusRoute(result.chains[i].address, chain)
            )
          );
          handleOutsideClick(true);
          return;
        }
      }
      if (NotSupportChains.includes(result.chains[i - 1].chain)) {
        dispatch(
          snackbarActions.showSnackBar({
            content: NotSupportChainToast,
            color: "error",
          })
        );
        return;
      }
      navigate(
        AppRoutes.getExactRoute(
          AppRoutes.getTokenGeniusRoute(
            result?.chains[i - 1].address,
            result.chains[i - 1].chain
          )
        )
      );
      handleOutsideClick(true);
    } else {
      navigate(
        AppRoutes.getExactRoute(
          AppRoutes.getWalletProfileRoute(
            result?.address,
            chain === Chains.All ? Chains.Ethereum : chain
          )
        )
      );
      handleOutsideClick(true);
    }
  };

  useEffect(() => {
    getTokenList();
  }, []);

  useEffect(() => {
    handleOutsideClick(true);
  }, [location.pathname]);

  return (
    <ClickAwayListener onClickAway={() => handleOutsideClick(true)}>
      <div
        className={`search-container ${
          showBorder && theme === ThemeTypes.dark ? "border-gradient" : ""
        } ${showResults ? "search-container-with-results" : ""}`}
      >
        <div className="search-icon-container" onClick={handleEnter}>
          {showBorder ? <SearchIcon /> : <SearchSmIcon />}
        </div>
        <input
          type="text"
          placeholder={
            placeholder
              ? placeholder
              : `Search Tokens / Wallets using names / addresses`
          }
          className="dm-sans-500"
          onKeyDown={handleKeyDown}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        {showResults ? (
          <div className="results-container">
            <div
              className={`bg ${
                showBorder && theme === ThemeTypes.dark ? "border-gradient" : ""
              }`}
            >
              {loading ? (
                <div className="loading-container">
                  <CircularProgress size={"30px"} />
                </div>
              ) : (
                <div className="results-wrapper">
                  <div className="results">
                    <div className="category-results">
                      {searchResults.map((item: ISearchData, index) => {
                        return (
                          <div
                            className="item-details"
                            key={index}
                            onClick={() => handleResultClick(item)}
                          >
                            {!item.address ? (
                              <Token
                                logo={item.logo}
                                symbol={`${item.name} (${item.symbol})`}
                              />
                            ) : (
                              <Token symbol={item.address} isAddress={true} />
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {(!searchResults.length || error) && (
                      <div className="empty-result-container dm-sans-400">
                        <p className="first-para">Sorry, no result found</p>
                        <p className="second-para">Please, try another way</p>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {savedSearchResults.length > 0 && (
                <>
                  <div className="divider"></div>
                  <div className="recent-searches-container">
                    <p className="heading text-primary-main dm-sans-400">
                      Recent searches
                    </p>
                    <div className="recent-searches">
                      {savedSearchResults.map((token: ISearchData, index) => (
                        <button
                          key={index}
                          className="recent-item button-primary-light"
                          onClick={() => handleResultClick(token)}
                        >
                          {token.symbol}
                        </button>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default SearchBar;
