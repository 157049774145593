import { IGetDexTxsParams } from "../../@types/api";
import { Chains } from "../constants";
import { BaseAPI } from "./BaseAPI";

interface ISummary {
  chain: string;
}

export class DexTransactionsAPI {
  getDexTransactions = async ({
    chain,
    page = 1,
    page_size = 50,
    ordering = "",
    total_count = false,
    filter_by = null,
    filter_values = "",
    advanced_filter,
  }: IGetDexTxsParams) => {
    return BaseAPI.callAPI({
      url: "/dex/transactions/",
      params: {
        chain: chain === Chains.All ? null : chain,
        page,
        page_size,
        ordering,
        total_count,
        filter_by,
        filter_values,
        from_ts: advanced_filter.from_ts,
        to_ts: advanced_filter.to_ts,
        bought_token_addresses: advanced_filter.bought_token_addresses,
        min_bought_token_amount: advanced_filter.min_bought_token_amount,
        max_bought_token_amount: advanced_filter.max_bought_token_amount,
        sold_token_addresses: advanced_filter.sold_token_addresses,
        min_sold_token_amount: advanced_filter.min_sold_token_amount,
        max_sold_token_amount: advanced_filter.max_sold_token_amount,
        trader_address: advanced_filter.trader_address,
        min_amount_usd: advanced_filter.min_amount_usd,
        max_amount_usd: advanced_filter.max_amount_usd,
        flg_smart_whales: advanced_filter.flg_smart_whales,
      },
      needToken: advanced_filter.apply_filter,
    });
  };

  static getSummary = async ({ chain }: ISummary) => {
    return BaseAPI.callAPI({
      url: "/dex/transactions/summary/",
      params: {
        chain: chain === Chains.All ? null : chain,
      },
      needToken: false,
    });
  };
}

export default new DexTransactionsAPI();
