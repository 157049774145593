import React from "react";

const ThemeMoonIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="20"
      viewBox="0 0 36 20"
      fill="none"
    >
      <rect width="36" height="20" rx="10" fill="white" />
      <g filter="url(#filter0_d_9394_54205)">
        <circle cx="26" cy="10" r="10" fill="url(#paint0_linear_9394_54205)" />
      </g>
      <path
        d="M25.9987 12.6668C27.4715 12.6668 28.6654 11.4729 28.6654 10.0002C28.6654 8.5274 27.4715 7.3335 25.9987 7.3335C24.5259 7.3335 23.332 8.5274 23.332 10.0002C23.332 11.4729 24.5259 12.6668 25.9987 12.6668Z"
        fill="white"
        stroke="#0F172A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.199 13.8L21.7324 14.2667M19.999 10H20.6657H19.999ZM25.999 4V4.66667V4ZM31.3324 10H31.999H31.3324ZM25.999 15.3333V16V15.3333ZM21.7324 5.73333L22.199 6.2L21.7324 5.73333ZM30.2657 5.73333L29.799 6.2L30.2657 5.73333ZM29.799 13.8L30.2657 14.2667L29.799 13.8Z"
        stroke="#0F172A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_9394_54205"
          x="16"
          y="0"
          width="22"
          height="22"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9394_54205"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9394_54205"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_9394_54205"
          x1="26"
          y1="9.50694e-07"
          x2="39.4422"
          y2="10.7453"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1EF7DD" />
          <stop offset="1" stopColor="#6553FB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ThemeMoonIcon;
