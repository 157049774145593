import React from "react";

const AllChainsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="chain-icon fill"
    >
      <path
        d="M22 21.3909H19.1432C19.1432 20.286 18.4513 19.2238 17.1932 18.4042C15.8222 17.5101 13.9787 17.0162 12.0011 17.0162C10.0234 17.0162 8.17988 17.5101 6.80894 18.4042C5.55082 19.2238 4.85684 20.2839 4.85684 21.3909H2C2 20.4287 2.28739 19.4984 2.85152 18.6256C3.37946 17.8124 4.12666 17.0886 5.07185 16.4712C6.9388 15.2536 9.39968 14.583 12.0011 14.583C14.6024 14.583 17.0633 15.2536 18.9303 16.4712C19.8755 17.0886 20.6205 17.8124 21.1485 18.6256C21.7147 19.4984 22 20.4287 22 21.3909Z"
        fill="#1AABE7"
      />
      <path
        d="M5.49973 18.2782C5.07398 19.2617 4.85897 20.309 4.85897 21.3905H2C2 21.3543 2.00213 21.3181 2.00213 21.2819C2.02342 20.358 2.30867 19.4639 2.85152 18.6252C3.37946 17.812 4.12666 17.0882 5.07185 16.4708C5.91911 15.9173 6.89196 15.4767 7.94359 15.1616C7.69026 15.3553 7.44545 15.564 7.21767 15.7875C6.48536 16.5028 5.90846 17.3394 5.49973 18.2782ZM22 21.2819C21.9787 20.358 21.6935 19.4639 21.1485 18.6252C20.6227 17.812 19.8755 17.0882 18.9303 16.4708C18.083 15.9173 17.1102 15.4767 16.0585 15.1616C16.3119 15.3553 16.5567 15.564 16.7845 15.7875C17.5168 16.5028 18.0958 17.3394 18.5003 18.2782C18.9282 19.2617 19.1432 20.309 19.1432 21.3905H22C22 21.3543 22 21.3181 22 21.2819Z"
        fill="#3A85E9"
      />
      <path
        d="M22 21.3906H19.1432C19.1432 19.198 18.9282 17.0713 18.5003 15.0745C18.0958 13.1714 17.5168 11.4705 16.7845 10.0186C16.0947 8.65194 15.2751 7.54923 14.4151 6.82757C13.6232 6.16551 12.8121 5.82916 12.0011 5.82916C11.1921 5.82916 10.3789 6.16551 9.58701 6.82757C8.72698 7.54923 7.9074 8.65194 7.21767 10.0186C6.48536 11.4705 5.90846 13.1714 5.50186 15.0745C5.07398 17.0734 4.85897 19.198 4.85897 21.3906H2C2 16.6413 2.94518 12.1517 4.66312 8.75412C5.53592 7.02129 6.57264 5.64822 7.74135 4.66897C8.3736 4.1389 9.04417 3.72805 9.73177 3.44918C10.4705 3.15114 11.2326 3 12.0011 3C12.7696 3 13.5317 3.15114 14.2704 3.44918C14.958 3.72805 15.6285 4.1389 16.2608 4.66897C17.4295 5.64822 18.4662 7.02129 19.339 8.75412C21.0548 12.1517 22 16.6413 22 21.3906Z"
        fill="#1AABE7"
      />
    </svg>
  );
};

export default AllChainsIcon;
