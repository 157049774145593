import { ThemeTypes } from "./constants";

export class LocalStorageKeys {
  static theme = "theme";
  static access_token = "access_token";
  static refresh_token = "refresh_token";
  static recent_searches = "recent_searches";
  static token_data = "token_data";
  static ref_code = "ref_code";
  static subscr_type = "subscr_type";
  static wallet_address = "wallet_address";
  static show_tutorial = "show_tutorial";
}

interface ISearchData {
  address: string;
  name: string;
  symbol: string;
  logo: string;
  chains: {
    chain: string;
    address: string;
  }[];
}

export class LocalStorages {
  static setTheme = (theme: string) => {
    localStorage.setItem(LocalStorageKeys.theme, theme);
  };

  static getTheme = () => {
    if (localStorage.getItem(LocalStorageKeys.theme))
      return localStorage.getItem(LocalStorageKeys.theme);
    else return ThemeTypes.defaultTheme;
  };

  static setAccessToken = (access_token: string) => {
    localStorage.setItem(LocalStorageKeys.access_token, access_token);
  };

  static getAccessToken = () => {
    return localStorage.getItem(LocalStorageKeys.access_token);
  };

  static getWalletAddress = () => {
    return localStorage.getItem(LocalStorageKeys.wallet_address);
  };

  static removeAccessToken = () => {
    if (localStorage.getItem(LocalStorageKeys.access_token))
      localStorage.removeItem(LocalStorageKeys.access_token);
    if (localStorage.getItem(LocalStorageKeys.refresh_token))
      localStorage.removeItem(LocalStorageKeys.refresh_token);
    if (localStorage.getItem(LocalStorageKeys.wallet_address))
      localStorage.removeItem(LocalStorageKeys.wallet_address);
  };

  static getTutorialStatus = () => {
    return localStorage.getItem(LocalStorageKeys.show_tutorial);
  };

  static hideTutorial = () => {
    if (localStorage.getItem(LocalStorageKeys.show_tutorial))
      localStorage.removeItem(LocalStorageKeys.show_tutorial);
  };

  static setRecentSearch = (token: ISearchData) => {
    let savedResults = this.getRecentSearches();

    for (const result of savedResults) if (result.name === token.name) return;

    savedResults.unshift(token);

    savedResults = savedResults.slice(0, 5);

    localStorage.setItem(
      LocalStorageKeys.recent_searches,
      JSON.stringify(savedResults)
    );
  };

  static getRecentSearches = (): ISearchData[] => {
    if (localStorage.getItem(LocalStorageKeys.recent_searches)) {
      return JSON.parse(
        localStorage.getItem(LocalStorageKeys.recent_searches) ?? "[]"
      );
    } else {
      return [];
    }
  };

  static getTokenData = () => {
    return localStorage.getItem(LocalStorageKeys.token_data);
  };

  static setTokenData = (data: []) => {
    localStorage.setItem(
      LocalStorageKeys.token_data,
      JSON.stringify({
        expires: Date.now() + 1000 * 60 * 60,
        data: data,
      })
    );
  };

  static getRefCode = () => {
    return localStorage.getItem(LocalStorageKeys.ref_code);
  };

  static getSubScrType = () => {
    const subscrType = localStorage.getItem(LocalStorageKeys.subscr_type);
    return subscrType ? parseInt(subscrType) : 0;
  };

  static setSubScrType = (subscrType: number) => {
    localStorage.setItem(LocalStorageKeys.subscr_type, subscrType.toString());
  };
}
