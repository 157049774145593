import { Skeleton } from "@mui/material";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { snackbarActions } from "../../redux/features/snackbarSlice";
import { sidebarStateSelector } from "../../redux/selectors/sidebarSelectors";
import { NotSupportChains, NotSupportChainToast } from "../../utils/constants";
import { AppRoutes } from "../../utils/routes";

import "./scss/Token.scss";
import "react-lazy-load-image-component/src/effects/blur.css";
import ContextMenu from "./ContextMenu";

interface ITokenParams {
  logo?: string;
  symbol: string;
  address?: string;
  isAddress?: boolean;
  name?: string;
  isLogoOnly?: boolean;
  tokenChain?: string;
  onClick?: () => void;
  width?: number;
  height?: number;
}

const Token = ({
  logo,
  symbol,
  name,
  address,
  isAddress = false,
  isLogoOnly = false,
  tokenChain,
  onClick,
  width = 24,
  height = 24,
}: ITokenParams) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { chain } = useSelector(sidebarStateSelector);

  const handleClickToken = (openNewTab = false) => {
    if (onClick && !openNewTab) {
      onClick();
      return;
    }
    if (address) {
      if (tokenChain) {
        if (NotSupportChains.includes(tokenChain)) {
          dispatch(
            snackbarActions.showSnackBar({
              content: NotSupportChainToast,
              color: "error",
            })
          );
          return;
        }
      }

      window.open(
        AppRoutes.getExactRoute(
          AppRoutes.getTokenGeniusRoute(address, tokenChain ?? chain)
        ),
        openNewTab ? "_blank" : "_self"
      );
    }
  };

  const renderToken = () => {
    return (
      <div className="token">
        {!isAddress && (
          <div className="token-logo" style={{ width: width, height: height }}>
            {loading && (
              <Skeleton
                animation="wave"
                variant="circular"
                width="100%"
                height="100%"
              />
            )}

            <LazyLoadImage
              src={logo ?? `assets/icons/${tokenChain ?? chain}.svg`}
              title={symbol}
              width="100%"
              height="100%"
              effect="blur"
              afterLoad={() => setLoading(false)}
              onError={(event: any) => {
                event.target.src = `assets/icons/${tokenChain ?? chain}.svg`;
                event.onerror = null;
              }}
            />
          </div>
        )}

        {!isLogoOnly && (
          <div
            className={`token-symbol ${name ? "text-secondary-normal" : ""}`}
          >
            {symbol}
            {name && <div className="token-name">{name}</div>}
          </div>
        )}
      </div>
    );
  };

  return address ? (
    <ContextMenu handleClick={() => handleClickToken(true)}>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => handleClickToken(false)}
      >
        {renderToken()}
      </div>
    </ContextMenu>
  ) : (
    <>{renderToken()}</>
  );
};

export default Token;
