import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { ICexAdvancedFilterQuery, ITokenCexSummary } from "../../@types/redux";
import { CexFlowsAPI } from "../../utils/api/CexFlowsAPI";
import { CexDirections } from "../../utils/constants";

const advancedFilterDefaultQuery = {
  from_ts: null,
  to_ts: null,
  cex_platform: "all",
  wallet_address: "",
  token_addresses: [],
  token_list: [],
  min_token_amount: null,
  max_token_amount: null,
  min_amount_usd: null,
  max_amount_usd: null,
  apply_filter: false,
} as ICexAdvancedFilterQuery;

const cexTransactionsDefaultQuery = {
  chain: "",
  page: 1,
  page_size: 10,
  direction: CexDirections.In,
  advanced_filter: advancedFilterDefaultQuery,
};

const initialState = {
  isRefreshing: false,
  loading: false,
  total: 0,
  transactions: [],
  summary: {
    total_usd_amount: 0,
    netflow_usd_amount: 0,
    avg_price: 0,
    num_txs: 0,
    loading: false,
  } as ITokenCexSummary,
  query: cexTransactionsDefaultQuery,
};

const CexTransactionsSlice = createSlice({
  name: "cexTransactions",
  initialState,
  reducers: {
    getList: (state, _) => {
      if (!state.isRefreshing) {
        state.loading = true;
        state.transactions = [];
      }
    },

    setList: (state, action: PayloadAction<any>) => {
      state.isRefreshing = false;
      state.loading = false;
      state.transactions = action.payload.transactions;
    },

    getTotalCount: (__, _) => {
      //get Total Count
    },

    setTotalCount: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },

    getSummary: (state, _) => {
      state.summary.loading = true;
    },

    setSummary: (state, action: PayloadAction<ITokenCexSummary>) => {
      state.total =
        action.payload.num_txs > 10000 ? 10000 : action.payload.num_txs;
      state.summary = action.payload;
      state.summary.loading = false;
    },

    setQuery: (state, action: PayloadAction<any>) => {
      state.query = action.payload;
    },

    setAdvancedFilterQuery: (state, action: PayloadAction<any>) => {
      state.query.advanced_filter = action.payload;
      state.query.page = 1;
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
      state.query = cexTransactionsDefaultQuery;
    },

    clearAllFilters: (state, _) => {
      state.query.advanced_filter = advancedFilterDefaultQuery;
      state.query.page = 1;
    },

    setDirection: (state, action: PayloadAction<string>) => {
      state.query = {
        ...cexTransactionsDefaultQuery,
        direction: action.payload,
      };
    },
  },
});

const cexTransactionsActions = CexTransactionsSlice.actions;
const cexTransactionsReducer = CexTransactionsSlice.reducer;

export { cexTransactionsActions, cexTransactionsReducer };

// Sagas

function* getCexTransactions(action: any): any {
  const res = yield call(CexFlowsAPI.getFlowTransactions, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      cexTransactionsActions.setList({
        transactions: res.data.results,
      })
    );
  } else {
    yield put(
      cexTransactionsActions.setList({
        transactions: [],
      })
    );
  }
}

function* getTotalCount(action: any): any {
  const res = yield call(CexFlowsAPI.getFlowTransactions, {
    ...action.payload,
    total_count: true,
  });

  if (!res.error) yield put(cexTransactionsActions.setTotalCount(res.data));
  else yield put(cexTransactionsActions.setTotalCount(0));
}

function* getSummary(action: any): any {
  const res = yield call(CexFlowsAPI.getFlowTransactions, {
    ...action.payload,
    flg_summary: true,
  });

  if (!res.error) yield put(cexTransactionsActions.setSummary(res.data));
  else yield put(cexTransactionsActions.setSummary(initialState.summary));
}

export function* cexTransactionsSaga() {
  yield all([takeLatest(cexTransactionsActions.getList, getCexTransactions)]);
  yield all([takeLatest(cexTransactionsActions.getTotalCount, getTotalCount)]);
  yield all([takeLatest(cexTransactionsActions.getSummary, getSummary)]);
}
