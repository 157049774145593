import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  IDexTransactions,
  ISmartWhalesCategory,
  ISmartWhalesDexTransactionsQuery,
} from "../../@types/redux";
import { SmartWhalesAPI } from "../../utils/api/SmartWhalesAPI";

export interface ISmartWhalesState {
  isRefreshing: boolean;
  loading: boolean;
  total: number;
  transactions: [];
  query: {
    page: number;
    page_size: number;
    total: boolean;
    ordering: string;
    smart_whales_categories: string[];
  };
}

export const smartWhalesDefaultQuery = {
  page: 1,
  page_size: 50,
  total: false,
  ordering: "",
  smart_whales_categories: [],
};

const initialState: ISmartWhalesState = {
  isRefreshing: false,
  loading: false,
  total: 0,
  transactions: [],
  query: {
    ...smartWhalesDefaultQuery,
  },
};

const smartWhalesSlice = createSlice({
  name: "smartWhales",
  initialState,
  reducers: {
    getList: (state, _: PayloadAction<ISmartWhalesDexTransactionsQuery>) => {
      if (!state.isRefreshing) {
        state.loading = true;
        state.transactions = [];
      }
    },

    setList: (state, action: PayloadAction<IDexTransactions>) => {
      state.isRefreshing = false;
      state.loading = false;
      state.total = action.payload.total;
      state.transactions = action.payload.transactions;
    },

    setQuery: (
      state,
      action: PayloadAction<ISmartWhalesDexTransactionsQuery>
    ) => {
      state.query.page_size = action.payload.page_size;
      state.query.page = action.payload.page;
      state.query.ordering = action.payload.ordering;
      state.query.smart_whales_categories =
        action.payload.smart_whales_categories;
    },

    setSmartWhalesCategory: (
      state,
      action: PayloadAction<ISmartWhalesCategory>
    ) => {
      state.query = {
        ...smartWhalesDefaultQuery,
        smart_whales_categories: action.payload.smart_whales_categories,
      };
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
      state.query = {
        ...smartWhalesDefaultQuery,
        smart_whales_categories: state.query.smart_whales_categories,
      };
    },
  },
});

const smartWhalesActions = smartWhalesSlice.actions;
const smartWhalesReducer = smartWhalesSlice.reducer;

export { smartWhalesActions, smartWhalesReducer };

// Sagas

function* getDexTransactions(action: any): any {
  const [dexTransactionsRes, totalNumberRes] = yield all([
    call(SmartWhalesAPI.getDexTransactions, {
      ...action.payload,
    }),
    call(SmartWhalesAPI.getDexTransactions, {
      ...action.payload,
      total_count: true,
    }),
  ]);

  if (!dexTransactionsRes.error) {
    if (!totalNumberRes.error) {
      yield put(
        smartWhalesActions.setList({
          transactions: dexTransactionsRes.data.transactions,
          total: totalNumberRes.data,
        })
      );
    } else {
      yield put(
        smartWhalesActions.setList({
          transactions: dexTransactionsRes.data.transactions,
          total: 0,
        })
      );
    }
  } else {
    yield put(
      smartWhalesActions.setList({
        transactions: [],
        total: 0,
      })
    );
  }
}

export function* smartWhalesSaga() {
  yield all([takeLatest(smartWhalesActions.getList, getDexTransactions)]);
}
