import React from "react";

const LockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
    >
      <path
        d="M12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12V18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V12C0 11.4696 0.210714 10.9609 0.585786 10.5858C0.960859 10.2107 1.46957 10 2 10V5C2 3.67392 2.52678 2.40215 3.46447 1.46447C4.40215 0.526784 5.67392 0 7 0C8.32608 0 9.59785 0.526784 10.5355 1.46447C11.4732 2.40215 12 3.67392 12 5V10ZM7 17C7.53043 17 8.03914 16.7893 8.41421 16.4142C8.78929 16.0391 9 15.5304 9 15C9 14.4696 8.78929 13.9609 8.41421 13.5858C8.03914 13.2107 7.53043 13 7 13C6.46957 13 5.96086 13.2107 5.58579 13.5858C5.21071 13.9609 5 14.4696 5 15C5 15.5304 5.21071 16.0391 5.58579 16.4142C5.96086 16.7893 6.46957 17 7 17ZM10 10V5C10 4.60603 9.9224 4.21593 9.77164 3.85195C9.62087 3.48797 9.3999 3.15726 9.12132 2.87868C8.84274 2.6001 8.51203 2.37913 8.14805 2.22836C7.78407 2.0776 7.39397 2 7 2C6.60603 2 6.21593 2.0776 5.85195 2.22836C5.48797 2.37913 5.15726 2.6001 4.87868 2.87868C4.6001 3.15726 4.37913 3.48797 4.22836 3.85195C4.0776 4.21593 4 4.60603 4 5V10H10Z"
        fill="url(#paint0_linear_9358_118654)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_118654"
          x1="16.5186"
          y1="14.738"
          x2="-0.981907"
          y2="3.74265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LockIcon;
