import React from "react";
import { useSelector } from "react-redux";
import { themeStateSelector } from "../../redux/selectors/themeSelectors";
import { ThemeTypes } from "../../utils/constants";

function ArbitrumIcon() {
  const theme = useSelector(themeStateSelector).theme;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className="chain-icon"
    >
      {theme === ThemeTypes.dark ? (
        <>
          <g clipPath="url(#clip0_9358_117820)">
            <path
              d="M1.98877 6.85365V15.146C1.98877 15.679 2.27917 16.1613 2.76317 16.4321L10.2344 20.5783C10.7096 20.8406 11.2992 20.8406 11.7744 20.5783L19.2456 16.4321C19.7208 16.1698 20.02 15.679 20.02 15.146V6.85365C20.02 6.32057 19.7296 5.83826 19.2456 5.56749L11.7744 1.42134C11.2992 1.15903 10.7096 1.15903 10.2344 1.42134L2.76317 5.56749C2.28797 5.8298 1.99757 6.32057 1.99757 6.85365H1.98877Z"
              fill="black"
            />
            <path
              d="M12.6281 12.6074L11.5633 15.4166C11.5369 15.4928 11.5369 15.5774 11.5633 15.662L13.3937 20.4935L15.5145 19.3174L12.9713 12.6074C12.9097 12.4551 12.6897 12.4551 12.6281 12.6074Z"
              fill="url(#paint0_linear_9358_117820)"
            />
            <path
              d="M14.7667 7.88572C14.7051 7.73341 14.4851 7.73341 14.4235 7.88572L13.3587 10.6949C13.3323 10.7711 13.3323 10.8557 13.3587 10.9403L16.3595 18.8519L18.4803 17.6757L14.7667 7.89418V7.88572Z"
              fill="url(#paint1_linear_9358_117820)"
            />
            <path
              d="M10.9999 1.73439C11.0527 1.73439 11.1055 1.75131 11.1495 1.7767L19.2279 6.26131C19.3247 6.31208 19.3775 6.41362 19.3775 6.51516V15.4844C19.3775 15.5859 19.3159 15.6875 19.2279 15.7382L11.1495 20.2229C11.1055 20.2482 11.0527 20.2652 10.9999 20.2652C10.9471 20.2652 10.8943 20.2482 10.8503 20.2229L2.77191 15.7382C2.67511 15.6875 2.62231 15.5859 2.62231 15.4844V6.5067C2.62231 6.40516 2.68391 6.30362 2.77191 6.25285L10.8503 1.76824C10.8943 1.74285 10.9471 1.72593 10.9999 1.72593V1.73439ZM10.9999 0.422852C10.7095 0.422852 10.4279 0.490544 10.1639 0.63439L2.08551 5.11901C1.56631 5.4067 1.24951 5.93131 1.24951 6.5067V15.4759C1.24951 16.0513 1.56631 16.5759 2.08551 16.8636L10.1639 21.3482C10.4191 21.4921 10.7095 21.5598 10.9999 21.5598C11.2903 21.5598 11.5719 21.4921 11.8359 21.3482L19.9143 16.8636C20.4335 16.5759 20.7503 16.0513 20.7503 15.4759V6.5067C20.7503 5.93131 20.4335 5.4067 19.9143 5.11901L11.8271 0.63439C11.5719 0.490544 11.2815 0.422852 10.9911 0.422852H10.9999Z"
              fill="url(#paint2_linear_9358_117820)"
            />
            <path
              d="M5.64941 18.8605L6.39741 16.8975L7.89341 18.0905L6.49421 19.3259L5.64941 18.8605Z"
              fill="url(#paint3_linear_9358_117820)"
            />
            <path
              d="M10.3136 5.87223H8.26321C8.11361 5.87223 7.97281 5.96531 7.92001 6.10069L3.52881 17.6761L5.64961 18.8522L10.4896 6.10069C10.5336 5.98223 10.4456 5.86377 10.3224 5.86377L10.3136 5.87223Z"
              fill="url(#paint4_linear_9358_117820)"
            />
            <path
              d="M13.9042 5.87223H11.8538C11.7042 5.87223 11.5634 5.96531 11.5106 6.10069L6.49463 19.3176L8.61543 20.4938L14.0714 6.10069C14.1154 5.98223 14.0274 5.86377 13.9042 5.86377V5.87223Z"
              fill="url(#paint5_linear_9358_117820)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_9358_117820"
              x1="16.2288"
              y1="18.3886"
              x2="10.452"
              y2="15.8151"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#04C3FF" />
              <stop offset="0.796666" stopColor="#79DEFE" />
              <stop offset="1" stopColor="#79DEFF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_9358_117820"
              x1="19.4052"
              y1="15.9366"
              x2="11.7662"
              y2="12.7552"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#04C3FF" />
              <stop offset="0.796666" stopColor="#79DEFE" />
              <stop offset="1" stopColor="#79DEFF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_9358_117820"
              x1="24.2585"
              y1="15.9986"
              x2="4.0894"
              y2="-0.702925"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#04C3FF" />
              <stop offset="0.796666" stopColor="#79DEFE" />
              <stop offset="1" stopColor="#79DEFF" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_9358_117820"
              x1="8.29711"
              y1="18.687"
              x2="5.97917"
              y2="16.7645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#04C3FF" />
              <stop offset="0.796666" stopColor="#79DEFE" />
              <stop offset="1" stopColor="#79DEFF" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_9358_117820"
              x1="11.7554"
              y1="15.435"
              x2="1.88963"
              y2="10.6815"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#04C3FF" />
              <stop offset="0.796666" stopColor="#79DEFE" />
              <stop offset="1" stopColor="#79DEFF" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_9358_117820"
              x1="15.4481"
              y1="16.6446"
              x2="4.57462"
              y2="11.5826"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#04C3FF" />
              <stop offset="0.796666" stopColor="#79DEFE" />
              <stop offset="1" stopColor="#79DEFF" />
            </linearGradient>
            <clipPath id="clip0_9358_117820">
              <rect
                width="22"
                height="21.1538"
                fill="white"
                transform="translate(0 0.422852)"
              />
            </clipPath>
          </defs>
        </>
      ) : (
        <>
          <g clipPath="url(#clip0_10139_91252)">
            <path
              d="M1.98828 6.85389V15.1462C1.98828 15.6793 2.27868 16.1616 2.76268 16.4324L10.2339 20.5785C10.7091 20.8408 11.2987 20.8408 11.7739 20.5785L19.2451 16.4324C19.7203 16.17 20.0195 15.6793 20.0195 15.1462V6.85389C20.0195 6.32082 19.7291 5.83851 19.2451 5.56774L11.7739 1.42158C11.2987 1.15928 10.7091 1.15928 10.2339 1.42158L2.76268 5.56774C2.28748 5.83005 1.99708 6.32082 1.99708 6.85389H1.98828Z"
              fill="white"
            />
            <path
              d="M12.6276 12.6076L11.5628 15.4169C11.5364 15.493 11.5364 15.5776 11.5628 15.6623L13.3932 20.4938L15.514 19.3176L12.9708 12.6076C12.9092 12.4553 12.6892 12.4553 12.6276 12.6076Z"
              fill="#28426C"
            />
            <path
              d="M14.7657 7.8862C14.7041 7.7339 14.4841 7.7339 14.4225 7.8862L13.3577 10.6954C13.3313 10.7716 13.3313 10.8562 13.3577 10.9408L16.3585 18.8524L18.4793 17.6762L14.7657 7.89466V7.8862Z"
              fill="#28426C"
            />
            <path
              d="M11.0004 1.73463C11.0532 1.73463 11.106 1.75156 11.15 1.77694L19.2284 6.26156C19.3252 6.31233 19.378 6.41387 19.378 6.5154V15.4846C19.378 15.5862 19.3164 15.6877 19.2284 15.7385L11.15 20.2231C11.106 20.2485 11.0532 20.2654 11.0004 20.2654C10.9476 20.2654 10.8948 20.2485 10.8508 20.2231L2.7724 15.7385C2.6756 15.6877 2.6228 15.5862 2.6228 15.4846V6.50694C2.6228 6.4054 2.6844 6.30386 2.7724 6.2531L10.8508 1.76848C10.8948 1.7431 10.9476 1.72617 11.0004 1.72617V1.73463ZM11.0004 0.423096C10.71 0.423096 10.4284 0.490788 10.1644 0.634634L2.086 5.11925C1.5668 5.40694 1.25 5.93156 1.25 6.50694V15.4762C1.25 16.0516 1.5668 16.5762 2.086 16.8639L10.1644 21.3485C10.4196 21.4923 10.71 21.56 11.0004 21.56C11.2908 21.56 11.5724 21.4923 11.8364 21.3485L19.9148 16.8639C20.434 16.5762 20.7508 16.0516 20.7508 15.4762V6.50694C20.7508 5.93156 20.434 5.40694 19.9148 5.11925L11.8276 0.634634C11.5724 0.490788 11.282 0.423096 10.9916 0.423096H11.0004Z"
              fill="#28426C"
            />
            <path
              d="M5.65039 18.8608L6.39839 16.8977L7.89439 18.0908L6.49519 19.3262L5.65039 18.8608Z"
              fill="#28426C"
            />
            <path
              d="M10.3141 5.87223H8.2637C8.1141 5.87223 7.9733 5.96531 7.9205 6.10069L3.5293 17.6761L5.6501 18.8522L10.4901 6.10069C10.5341 5.98223 10.4461 5.86377 10.3229 5.86377L10.3141 5.87223Z"
              fill="#28426C"
            />
            <path
              d="M13.9037 5.87223H11.8533C11.7037 5.87223 11.5629 5.96531 11.5101 6.10069L6.49414 19.3176L8.61494 20.4938L14.0709 6.10069C14.1149 5.98223 14.0269 5.86377 13.9037 5.86377V5.87223Z"
              fill="#28426C"
            />
          </g>
          <defs>
            <clipPath id="clip0_10139_91252">
              <rect
                width="22"
                height="21.1538"
                fill="white"
                transform="translate(0 0.423096)"
              />
            </clipPath>
          </defs>
        </>
      )}
    </svg>
  );
}

export default ArbitrumIcon;
