import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { PerpDexTraderAPI } from "../../utils/api/PerpDexTraderAPI";

const initialState = {
  traderData: {
    data: {
      num_open_trades: 0,
      num_win: 0,
      num_lose: 0,
      best_monthly_trader_pnl: 0,
      best_weekly_trader_pnl: 0,
      best_daily_trader_pnl: 0,
    },
    loading: false,
  },
  avgHoursHeld: {
    data: {
      history: [] as {
        date: string;
        sum_pnl: number;
        wbtc_price: number;
        weth_price: number;
        num_trades: number;
        avg_hour: number;
        win_rate: number;
        cumulative_pnl: number;
        cumulativent: number;
      }[],
    },
    loading: false,
  },
  avgHoursHeldTop: {
    data: {
      history: [] as {
        date: string;
        group: string;
        hour_diff: number;
      }[],
    },
    loading: false,
  },
  pnlCategories: {
    data: {
      history: [] as {
        date: string;
        pnl_group: string;
        num_traders: number;
      }[],
    },
    loading: false,
  },
};

const perpDexTraderSlice = createSlice({
  name: "perpDexTrader",
  initialState,
  reducers: {
    getTraderData: (state, _) => {
      state.traderData.loading = true;
      state.traderData.data = initialState.traderData.data;
    },
    setTraderData: (state, action: PayloadAction<any>) => {
      state.traderData.loading = false;
      state.traderData.data = action.payload;
    },
    getAvgHoursHeld: (state, _) => {
      state.avgHoursHeld.loading = true;
      state.avgHoursHeld.data = initialState.avgHoursHeld.data;
    },
    setAvgHoursHeld: (state, action: PayloadAction<any>) => {
      state.avgHoursHeld.loading = false;
      state.avgHoursHeld.data = action.payload;
    },
    getAvgHoursHeldTop: (state, _) => {
      state.avgHoursHeldTop.loading = true;
      state.avgHoursHeldTop.data = initialState.avgHoursHeldTop.data;
    },
    setAvgHoursHeldTop: (state, action: PayloadAction<any>) => {
      state.avgHoursHeldTop.loading = false;
      state.avgHoursHeldTop.data = action.payload;
    },
    getPnlCategories: (state, _) => {
      state.pnlCategories.loading = true;
      state.pnlCategories.data = initialState.pnlCategories.data;
    },
    setPnlCategories: (state, action: PayloadAction<any>) => {
      state.pnlCategories.loading = false;
      state.pnlCategories.data = action.payload;
    },
  },
});

export const perpDexTraderActions = perpDexTraderSlice.actions;
export const perpDexTraderReducer = perpDexTraderSlice.reducer;

// Sagas

function* getTraderData(action: any): any {
  const res = yield call(PerpDexTraderAPI.getTraderData as any, {
    ...action.payload,
  });
  if (!res.error) yield put(perpDexTraderActions.setTraderData(res.data));
  else
    yield put(perpDexTraderActions.setTraderData(initialState.traderData.data));
}

function* getAvgHoursHeld(action: any): any {
  const res = yield call(PerpDexTraderAPI.getAvgHoursHeld as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(perpDexTraderActions.setAvgHoursHeld(res.data));
  } else {
    yield put(
      perpDexTraderActions.setAvgHoursHeld(initialState.avgHoursHeld.data)
    );
  }
}

function* getAvgHoursHeldTop(action: any): any {
  const res = yield call(PerpDexTraderAPI.getAvgHoursHeldTop as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(perpDexTraderActions.setAvgHoursHeldTop(res.data));
  } else {
    yield put(
      perpDexTraderActions.setAvgHoursHeldTop(initialState.avgHoursHeldTop.data)
    );
  }
}

function* getPnlCategories(action: any): any {
  const res = yield call(PerpDexTraderAPI.getPnlCategories as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(perpDexTraderActions.setPnlCategories(res.data));
  } else {
    yield put(
      perpDexTraderActions.setPnlCategories(initialState.pnlCategories.data)
    );
  }
}

export function* perpDexTraderSaga() {
  yield all([
    takeLatest(perpDexTraderActions.getTraderData, getTraderData),
    takeLatest(perpDexTraderActions.getAvgHoursHeld, getAvgHoursHeld),
    takeLatest(perpDexTraderActions.getAvgHoursHeldTop, getAvgHoursHeldTop),
    takeLatest(perpDexTraderActions.getPnlCategories, getPnlCategories),
  ]);
}
