import React from "react";

const DiscordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      className="fill"
    >
      <path
        d="M10.1585 1.19594C9.39367 0.846021 8.57349 0.588216 7.71595 0.440561C7.70033 0.437711 7.68473 0.444833 7.67669 0.459076C7.57121 0.646137 7.45437 0.890174 7.37255 1.08199C6.4502 0.944303 5.53259 0.944303 4.62916 1.08199C4.54732 0.88591 4.42624 0.646137 4.32029 0.459076C4.31224 0.445308 4.29664 0.438186 4.28102 0.440561C3.42395 0.587744 2.60378 0.845549 1.83847 1.19594C1.83184 1.19879 1.82616 1.20354 1.82239 1.20971C0.266696 3.52712 -0.159475 5.78757 0.0495901 8.02C0.050536 8.03092 0.0566849 8.04136 0.065199 8.048C1.0916 8.79958 2.08585 9.25585 3.06164 9.55828C3.07726 9.56303 3.0938 9.55734 3.10374 9.54451C3.33456 9.23022 3.54032 8.89882 3.71674 8.55032C3.72715 8.52991 3.71721 8.50569 3.69593 8.49762C3.36957 8.37418 3.0588 8.22367 2.75986 8.05276C2.73622 8.03899 2.73433 8.00527 2.75608 7.98912C2.81899 7.94212 2.88191 7.89322 2.94198 7.84384C2.95285 7.83482 2.96799 7.83292 2.98077 7.83862C4.94464 8.73264 7.07077 8.73264 9.01148 7.83862C9.02425 7.83245 9.0394 7.83435 9.05074 7.84337C9.11082 7.89275 9.17373 7.94212 9.23711 7.98912C9.25886 8.00527 9.25744 8.03899 9.2338 8.05276C8.93486 8.22699 8.62409 8.37418 8.29726 8.49715C8.27598 8.50522 8.26651 8.52991 8.27692 8.55032C8.45713 8.89833 8.66288 9.22973 8.88945 9.54404C8.89891 9.55734 8.91593 9.56303 8.93155 9.55828C9.91207 9.25585 10.9063 8.79958 11.9327 8.048C11.9417 8.04136 11.9474 8.03139 11.9483 8.02047C12.1985 5.43954 11.5292 3.19763 10.1741 1.21018C10.1708 1.20354 10.1651 1.19879 10.1585 1.19594ZM4.01001 6.66068C3.41875 6.66068 2.93157 6.11944 2.93157 5.45474C2.93157 4.79004 3.4093 4.2488 4.01001 4.2488C4.61544 4.2488 5.0979 4.79479 5.08844 5.45474C5.08844 6.11944 4.6107 6.66068 4.01001 6.66068ZM7.99737 6.66068C7.40613 6.66068 6.91895 6.11944 6.91895 5.45474C6.91895 4.79004 7.39666 4.2488 7.99737 4.2488C8.60282 4.2488 9.08527 4.79479 9.07582 5.45474C9.07582 6.11944 8.60282 6.66068 7.99737 6.66068Z"
        fill="url(#paint0_linear_8188_50536)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8188_50536"
          x1="14.1588"
          y1="7.16046"
          x2="5.42223"
          y2="-3.15733"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DiscordIcon;
