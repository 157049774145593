import { BaseAPI } from "./BaseAPI";

export class PerpDexTraderAPI {
  static getTraderData = async () => {
    return BaseAPI.callAPI({
      url: "/dex/perp/gmx_traders/data/",
    });
  };

  static getAvgHoursHeld = async () => {
    return BaseAPI.callAPI({
      url: "/dex/perp/gmx_traders/avg_hours_held/",
    });
  };

  static getAvgHoursHeldTop = async () => {
    return BaseAPI.callAPI({
      url: "/dex/perp/gmx_traders/avg_hours_held_top/",
    });
  };

  static getPnlCategories = async () => {
    return BaseAPI.callAPI({
      url: "/dex/perp/gmx_traders/pnl_categories/",
    });
  };
}
