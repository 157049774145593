import React from "react";

function PolygonIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      className="chain-icon fill"
    >
      <path
        d="M15.1436 5.62127C14.7781 5.41239 14.3081 5.41239 13.8903 5.62127L10.9661 7.3445L8.98172 8.44111L6.10966 10.1643C5.74412 10.3732 5.27415 10.3732 4.8564 10.1643L2.61097 8.80664C2.24543 8.59777 1.98433 8.18001 1.98433 7.71004V5.09907C1.98433 4.68132 2.19321 4.26356 2.61097 4.00247L4.8564 2.69698C5.22193 2.48811 5.69191 2.48811 6.10966 2.69698L8.35509 4.05469C8.72063 4.26356 8.98172 4.68132 8.98172 5.15129V6.87453L10.9661 5.7257V3.95025C10.9661 3.53249 10.7572 3.11474 10.3394 2.85364L6.16188 0.399334C5.79634 0.190456 5.32637 0.190456 4.90862 0.399334L0.626632 2.90586C0.208877 3.11474 0 3.53249 0 3.95025V8.85886C0 9.27662 0.208877 9.69437 0.626632 9.95547L4.8564 12.4098C5.22193 12.6187 5.69191 12.6187 6.10966 12.4098L8.98172 10.7388L10.9661 9.58993L13.8381 7.91892C14.2037 7.71004 14.6736 7.71004 15.0914 7.91892L17.3368 9.2244C17.7023 9.43328 17.9634 9.85103 17.9634 10.321V12.932C17.9634 13.3497 17.7546 13.7675 17.3368 14.0286L15.1436 15.3341C14.7781 15.5429 14.3081 15.5429 13.8903 15.3341L11.6449 14.0286C11.2794 13.8197 11.0183 13.4019 11.0183 12.932V11.261L9.03394 12.4098V14.133C9.03394 14.5508 9.24282 14.9685 9.66057 15.2296L13.8903 17.6839C14.2559 17.8928 14.7258 17.8928 15.1436 17.6839L19.3734 15.2296C19.7389 15.0207 20 14.603 20 14.133V9.17218C20 8.75443 19.7911 8.33667 19.3734 8.07557L15.1436 5.62127Z"
        fill="url(#paint0_linear_9358_117854)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_117854"
          x1="23.598"
          y1="13.2106"
          x2="6.50954"
          y2="-4.22081"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PolygonIcon;
