import React from "react";

const WatchlistIcon2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="stroke"
    >
      <path
        d="M20 13.0001C20 16.3001 17.3 19.0001 14 19.0001H10C8.3 19.0001 6.8 18.3001 5.8 17.2001C5 16.5001 4.5 15.5001 4.2 14.5001H3V11.5001H4.2C4.8 9.2001 6.8 7.4001 9.2 7.1001C9.6 8.2001 10.7 9.0001 12 9.0001C13.3 9.0001 14.4 8.2001 14.8 7.1001C16.1 7.3001 17.3 7.9001 18.2 8.8001C19.3 9.8001 20 11.3001 20 13.0001Z"
        stroke="url(#paint0_linear_9358_118118)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 21V19"
        stroke="url(#paint1_linear_9358_118118)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 21V19"
        stroke="url(#paint2_linear_9358_118118)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 13H20"
        stroke="url(#paint3_linear_9358_118118)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 6C15 6.4 14.9 6.7 14.8 7.1C14.4 8.2 13.3 9 12 9C10.7 9 9.6 8.2 9.2 7.1C9.1 6.7 9 6.4 9 6C9 4.3 10.3 3 12 3C13.7 3 15 4.3 15 6Z"
        stroke="url(#paint4_linear_9358_118118)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11H15"
        stroke="url(#paint5_linear_9358_118118)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_118118"
          x1="23.0583"
          y1="15.8692"
          x2="11.8487"
          y2="1.49601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9358_118118"
          x1="10.1799"
          y1="20.4738"
          x2="8.72869"
          y2="19.8225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9358_118118"
          x1="16.1799"
          y1="20.4738"
          x2="14.7287"
          y2="19.8225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9358_118118"
          x1="21.1799"
          y1="13.7369"
          x2="20.2143"
          y2="12.8702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9358_118118"
          x1="16.0794"
          y1="7.42139"
          x2="10.2858"
          y2="2.22136"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_9358_118118"
          x1="16.0794"
          y1="11.7369"
          x2="15.7307"
          y2="9.85916"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WatchlistIcon2;
