import {
  IGetTopAccumulationsParams,
  IGetNetAccumulationsParams,
  INewWalletAccumulationsParams,
  IGetTotalAccumulationsParams,
} from "../../@types/api";
import { BaseAPI } from "./BaseAPI";

interface ITokenStatistics {
  chain: string;
  num_hrs: number;
}

export class AccumulationsAPI {
  static getTotalAccumulations = async ({
    chain,
    page,
    page_size,
    ordering = "",
  }: IGetTotalAccumulationsParams) => {
    return BaseAPI.callAPI({
      url: "/dex/accumulations/top_overall/",
      params: {
        chain,
        page,
        page_size,
        ordering,
      },
    });
  };

  static getTopAccumulations = async ({
    chain,
    page,
    page_size,
    smart_whales_categories,
    ordering = "",
  }: IGetTopAccumulationsParams) => {
    return BaseAPI.callAPI({
      url: "/dex/accumulations/top/",
      params: {
        chain,
        page,
        page_size,
        smart_whale_categories:
          smart_whales_categories.length === 0 ? null : smart_whales_categories,
        ordering,
      },
    });
  };

  static getNetAccumulations = async ({
    chain,
    page,
    page_size,
    smart_whales_categories,
    ordering = "",
  }: IGetNetAccumulationsParams) => {
    return BaseAPI.callAPI({
      url: "/dex/accumulations/net/",
      params: {
        chain,
        page,
        page_size,
        smart_whale_categories:
          smart_whales_categories.length === 0 ? null : smart_whales_categories,
        ordering,
      },
    });
  };

  static getNewWalletAccumulations = async ({
    chain,
    page,
    page_size,
    ordering = "",
  }: INewWalletAccumulationsParams) => {
    return BaseAPI.callAPI({
      url: "/dex/accumulations/fresh_overall/",
      params: {
        chain,
        page,
        page_size,
        ordering,
      },
    });
  };

  static getTokenStatistics = async ({ chain, num_hrs }: ITokenStatistics) => {
    return BaseAPI.callAPI({
      url: "/dex/accumulations/top_overall/statistics/",
      params: {
        chain,
        num_hrs,
      },
    });
  };

  static getNewWalletAccumulationStatistics = async ({
    chain,
    num_hrs,
  }: ITokenStatistics) => {
    return BaseAPI.callAPI({
      url: "/dex/accumulations/fresh_overall/statistics/",
      params: {
        chain,
        num_hrs,
      },
    });
  };
}
