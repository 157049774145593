import React from "react";

const SidebarOnChainIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="fill"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6279 2.34882C11.8585 2.21706 12.1415 2.21706 12.3721 2.34882L22.8721 8.34882C23.1058 8.48235 23.25 8.73086 23.25 9C23.25 9.26914 23.1058 9.51765 22.8721 9.65118L12.3721 15.6512C12.1415 15.7829 11.8585 15.7829 11.6279 15.6512L1.1279 9.65118C0.894215 9.51765 0.75 9.26914 0.75 9C0.75 8.73086 0.894215 8.48235 1.1279 8.34882L11.6279 2.34882ZM3.01167 9L12 14.1362L20.9883 9L12 3.86381L3.01167 9Z"
        fill="url(#paint0_linear_9358_118230)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 10.5C5.66421 10.5 6 10.8358 6 11.25V16.8087L12 20.142L18 16.8087V11.25C18 10.8358 18.3358 10.5 18.75 10.5C19.1642 10.5 19.5 10.8358 19.5 11.25V17.25C19.5 17.5224 19.3523 17.7733 19.1142 17.9056L12.3642 21.6556C12.1377 21.7815 11.8623 21.7815 11.6358 21.6556L4.88577 17.9056C4.64767 17.7733 4.5 17.5224 4.5 17.25V11.25C4.5 10.8358 4.83579 10.5 5.25 10.5Z"
        fill="url(#paint1_linear_9358_118230)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 8.25C22.9142 8.25 23.25 8.58579 23.25 9V17.25C23.25 17.6642 22.9142 18 22.5 18C22.0858 18 21.75 17.6642 21.75 17.25V9C21.75 8.58579 22.0858 8.25 22.5 8.25Z"
        fill="url(#paint2_linear_9358_118230)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14.25C12.4142 14.25 12.75 14.5858 12.75 15V21C12.75 21.4142 12.4142 21.75 12 21.75C11.5858 21.75 11.25 21.4142 11.25 21V15C11.25 14.5858 11.5858 14.25 12 14.25Z"
        fill="url(#paint3_linear_9358_118230)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_118230"
          x1="27.2978"
          y1="12.1981"
          x2="15.1819"
          y2="-5.92617"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9358_118230"
          x1="22.1985"
          y1="18.7901"
          x2="11.4459"
          y2="5.92209"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9358_118230"
          x1="23.5199"
          y1="15.4348"
          x2="20.9536"
          y2="15.0804"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9358_118230"
          x1="13.0199"
          y1="19.7767"
          x2="10.4863"
          y2="19.3219"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SidebarOnChainIcon;
