import React from "react";

const SidebarCloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M19.3334 12.6667L12.6667 19.3333M12.6667 12.6666L19.3334 19.3333"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SidebarCloseIcon;
