import { IGetTokenTopDexTradersParams } from "../../@types/api";
import { BaseAPI } from "./BaseAPI";

export class TokenTopDexTradersAPI {
  static getTopDexTraders = async ({
    chain,
    page = 1,
    page_size = 20,
    token_address,
  }: IGetTokenTopDexTradersParams) => {
    return BaseAPI.callAPI({
      url: `token/${token_address}/top_dex_traders/`,
      params: {
        chain,
        page,
        page_size,
      },
    });
  };
}
