import React from "react";

const ProfileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="stroke"
    >
      <path
        d="M19 7H5C3.9 7 3 7.9 3 9V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V9C21 7.9 20.1 7 19 7Z"
        stroke="url(#paint0_linear_9358_118108)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9999 7L15.5999 3.6C14.8999 2.8 13.5999 2.8 12.7999 3.6L11.9999 4.4L9.3999 7"
        stroke="url(#paint1_linear_9358_118108)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 7L8.4 3.6C9.2 2.8 10.5 2.8 11.2 3.6L12 4.4"
        stroke="url(#paint2_linear_9358_118108)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 14.5C16.8 14.5 17 14.3 17 14C17 13.7 16.8 13.5 16.5 13.5C16.2 13.5 16 13.7 16 14C16 14.3 16.2 14.5 16.5 14.5Z"
        fill="url(#paint3_linear_9358_118108)"
        stroke="url(#paint4_linear_9358_118108)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_118108"
          x1="24.2382"
          y1="17.3166"
          x2="10.7791"
          y2="1.78484"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9358_118108"
          x1="20.727"
          y1="5.9476"
          x2="17.7595"
          y2="-0.444761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9358_118108"
          x1="13.2593"
          y1="5.9476"
          x2="9.7393"
          y2="0.418671"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9358_118108"
          x1="17.1799"
          y1="14.2369"
          x2="16.2143"
          y2="13.3702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9358_118108"
          x1="17.1799"
          y1="14.2369"
          x2="16.2143"
          y2="13.3702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProfileIcon;
