import React from "react";

export const BaseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      className="chain-icon fill"
    >
      <path
        d="M9.9824 20.5835C15.5152 20.5835 20 16.1067 20 10.5835C20 5.0603 15.5152 0.583496 9.9824 0.583496C4.7336 0.583496 0.428 4.6139 0 9.7427H13.2408V11.4243H0C0.428 16.5531 4.7336 20.5835 9.9824 20.5835Z"
        fill="url(#paint0_linear_9358_117866)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_117866"
          x1="23.598"
          y1="15.3215"
          x2="4.28607"
          y2="-2.01199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BaseIcon;
