import jwtDecode from "jwt-decode";
import { LocalStorages } from "../utils/localStorages";

export class AuthHelpers {
  static isUserLoggedIn = () => {
    const accessToken = LocalStorages.getAccessToken();
    const walletAddress = LocalStorages.getWalletAddress();
    if (accessToken && walletAddress) {
      try {
        const decodedToken = jwtDecode(accessToken) as any;
        const expiresTime = (decodedToken?.exp || 0) * 1000;
        return expiresTime > new Date().getTime();
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  };
}
