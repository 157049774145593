import React from "react";

export const OptimismIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      className="chain-icon fill"
    >
      <g clipPath="url(#clip0_9358_117872)">
        <path
          d="M11 22.0835C17.0751 22.0835 22 17.1586 22 11.0835C22 5.00836 17.0751 0.0834961 11 0.0834961C4.92487 0.0834961 0 5.00836 0 11.0835C0 17.1586 4.92487 22.0835 11 22.0835Z"
          fill="url(#paint0_linear_9358_117872)"
        />
        <path
          d="M7.79232 14.0049C7.13672 14.0049 6.59992 13.8509 6.18192 13.5429C5.76832 13.2305 5.56152 12.7817 5.56152 12.2053C5.56152 12.0821 5.57472 11.9369 5.60112 11.7609C5.67152 11.3649 5.77272 10.8897 5.90472 10.3309C6.27872 8.81735 7.24672 8.06055 8.80432 8.06055C9.22672 8.06055 9.60952 8.13095 9.94392 8.27615C10.2783 8.41255 10.5423 8.62375 10.7359 8.90535C10.9295 9.18255 11.0263 9.51255 11.0263 9.89535C11.0263 10.0097 11.0131 10.1549 10.9867 10.3309C10.9031 10.8193 10.8063 11.2989 10.6875 11.7609C10.4939 12.5133 10.1639 13.0809 9.68872 13.4549C9.21792 13.8245 8.58432 14.0049 7.79232 14.0049ZM7.91112 12.8169C8.21912 12.8169 8.47872 12.7245 8.69432 12.5441C8.91432 12.3637 9.07272 12.0865 9.16512 11.7081C9.29272 11.1889 9.38952 10.7401 9.45552 10.3529C9.47752 10.2385 9.49072 10.1197 9.49072 9.99655C9.49072 9.49495 9.23112 9.24415 8.70752 9.24415C8.39952 9.24415 8.13552 9.33655 7.91552 9.51695C7.69992 9.69735 7.54592 9.97455 7.45352 10.3529C7.35232 10.7225 7.25552 11.1713 7.15432 11.7081C7.13232 11.8181 7.11912 11.9325 7.11912 12.0557C7.11472 12.5661 7.38312 12.8169 7.91112 12.8169Z"
          fill="white"
          className="no-touch"
        />
        <path
          d="M11.4093 13.9261C11.3477 13.9261 11.3037 13.9085 11.2685 13.8689C11.2421 13.8249 11.2333 13.7765 11.2421 13.7193L12.3817 8.35133C12.3905 8.28973 12.4213 8.24133 12.4741 8.20173C12.5225 8.16213 12.5753 8.14453 12.6325 8.14453H14.8281C15.4397 8.14453 15.9281 8.27213 16.2977 8.52293C16.6717 8.77813 16.8609 9.14333 16.8609 9.62293C16.8609 9.75933 16.8433 9.90453 16.8125 10.0541C16.6761 10.6877 16.3989 11.1541 15.9765 11.4577C15.5629 11.7613 14.9953 11.9109 14.2737 11.9109H13.1605L12.7821 13.7193C12.7689 13.7809 12.7425 13.8293 12.6897 13.8689C12.6413 13.9085 12.5885 13.9261 12.5313 13.9261H11.4093ZM14.3309 10.7713C14.5641 10.7713 14.7621 10.7097 14.9337 10.5821C15.1097 10.4545 15.2241 10.2741 15.2813 10.0365C15.2989 9.94413 15.3077 9.86053 15.3077 9.79013C15.3077 9.63173 15.2593 9.50853 15.1669 9.42493C15.0745 9.33693 14.9117 9.29293 14.6873 9.29293H13.6973L13.3849 10.7713H14.3309Z"
          fill="white"
          className="no-touch"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_9358_117872"
          x1="25.9578"
          y1="16.2953"
          x2="4.71468"
          y2="-2.77153"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <clipPath id="clip0_9358_117872">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0 0.0834961)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OptimismIcon;
