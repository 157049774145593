import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { Chains } from "../../utils/constants";

const initialState = {
  isLocked: false,
  chain: Chains.Ethereum,
  isDexExpanded: true,
  isDiscoveryExpanded: true,
  isPerpDexDataExpanded: true,
  isOpened: false,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleLock: (state, _) => {
      state.isLocked = !state.isLocked;
    },

    openSidebar: (state, _) => {
      state.isOpened = true;
    },

    closeSidebar: (state, _) => {
      state.isOpened = false;
    },

    toggleDex: (state, _) => {
      state.isDexExpanded = !state.isDexExpanded;
    },

    expandDex: (state, _) => {
      state.isDexExpanded = true;
    },

    togglePerpDexData: (state, _) => {
      state.isPerpDexDataExpanded = !state.isPerpDexDataExpanded;
    },

    expandPerpDexData: (state, _) => {
      state.isPerpDexDataExpanded = true;
    },

    toggleDiscovery: (state, _) => {
      state.isDiscoveryExpanded = !state.isDiscoveryExpanded;
    },

    expandDiscovery: (state, _) => {
      state.isDiscoveryExpanded = true;
    },

    setCurrentChain: (state, action: PayloadAction<string>) => {
      state.chain = action.payload;
    },
  },
});

const persistConfig = {
  key: "Alphanomics-root-state",
  storage,
};

export const sidebarActions = sidebarSlice.actions;

export const sidebarReducer = persistReducer(
  persistConfig,
  sidebarSlice.reducer
);
