import React from "react";

function EthereumIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="chain-icon stroke"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15.5L5 12L12 3L19 12L12 15.5Z"
        stroke="url(#paint0_linear_9358_118087)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 12L12 21L5 12"
        stroke="url(#paint1_linear_9358_118087)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_118087"
          x1="21.5186"
          y1="12.2112"
          x2="9.37824"
          y2="0.00703835"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9358_118087"
          x1="21.5186"
          y1="18.6321"
          x2="13.2426"
          y2="7.07725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default EthereumIcon;
