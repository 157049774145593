import React from "react";

import "./scss/ErrorPage.scss";

const ErrorPage = () => {
  const onContactSupport = () => {
    window.open("mailto:support@alphanomics.io");
  };

  return (
    <div className="main-container error-page-container">
      <h1>Oops! Something went wrong</h1>
      <h5>
        Please, try to refresh the page. If it keeps not working reach out to us
      </h5>
      <button className="button-primary" onClick={onContactSupport}>
        Contact Support
      </button>
    </div>
  );
};

export default ErrorPage;
