import React from "react";

const SignOutIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill"
    >
      <path
        d="M7.88023 12.07C7.88023 11.66 8.22023 11.32 8.63023 11.32H14.1102V2.86C14.1002 2.38 13.7202 2 13.2402 2C7.35023 2 3.24023 6.11 3.24023 12C3.24023 17.89 7.35023 22 13.2402 22C13.7102 22 14.1002 21.62 14.1002 21.14V12.81H8.63023C8.21023 12.82 7.88023 12.48 7.88023 12.07Z"
        fill="url(#paint0_linear_773_4070)"
      />
      <path
        d="M20.5396 11.5399L17.6996 8.68991C17.4096 8.39991 16.9296 8.39991 16.6396 8.68991C16.3496 8.97991 16.3496 9.45991 16.6396 9.74991L18.1996 11.3099H14.0996V12.8099H18.1896L16.6296 14.3699C16.3396 14.6599 16.3396 15.1399 16.6296 15.4299C16.7796 15.5799 16.9696 15.6499 17.1596 15.6499C17.3496 15.6499 17.5396 15.5799 17.6896 15.4299L20.5296 12.5799C20.8296 12.2999 20.8296 11.8299 20.5396 11.5399Z"
        fill="url(#paint1_linear_773_4070)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_773_4070"
          x1="8.67523"
          y1="2"
          x2="8.67523"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E7F4" />
          <stop offset="1" stopColor="#F0F2F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_773_4070"
          x1="17.4277"
          y1="8.47241"
          x2="17.4277"
          y2="15.6499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E7F4" />
          <stop offset="1" stopColor="#F0F2F7" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SignOutIcon;
