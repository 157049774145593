import React from "react";

const DarkLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="207"
      height="32"
      viewBox="0 0 207 32"
      fill="none"
      className="alphanomics-logo"
    >
      <path
        d="M34.5156 31.8723H29.6195C29.6195 29.9788 28.4337 28.1582 26.2776 26.7536C23.928 25.2213 20.7686 24.3749 17.3792 24.3749C13.9899 24.3749 10.8304 25.2213 8.48089 26.7536C6.32471 28.1582 5.13535 29.9751 5.13535 31.8723H0.239258C0.239258 30.2232 0.731785 28.6289 1.6986 27.1331C2.60339 25.7394 3.88396 24.4989 5.50383 23.4409C8.70344 21.3541 12.9209 20.2048 17.3792 20.2048C21.8375 20.2048 26.055 21.3541 29.2546 23.4409C30.8745 24.4989 32.1514 25.7394 33.0562 27.1331C34.0267 28.6289 34.5156 30.2232 34.5156 31.8723Z"
        fill="#1AABE7"
      />
      <path
        d="M6.23715 26.5382C5.50748 28.2238 5.139 30.0187 5.139 31.8721H0.239258C0.239258 31.8101 0.242906 31.7481 0.242906 31.686C0.279389 30.1027 0.768269 28.5703 1.6986 27.1329C2.60339 25.7392 3.88396 24.4988 5.50383 23.4408C6.95588 22.4922 8.62318 21.737 10.4255 21.197C9.99131 21.529 9.57175 21.8866 9.18138 22.2696C7.92634 23.4955 6.93764 24.9293 6.23715 26.5382ZM34.5156 31.686C34.4791 30.1027 33.9902 28.5703 33.0562 27.1329C32.1551 25.7392 30.8745 24.4988 29.2546 23.4408C27.8026 22.4922 26.1353 21.737 24.333 21.197C24.7672 21.529 25.1867 21.8866 25.5771 22.2696C26.8321 23.4955 27.8245 24.9293 28.5177 26.5382C29.251 28.2238 29.6195 30.0187 29.6195 31.8721H34.5156C34.5156 31.8101 34.5156 31.7481 34.5156 31.686Z"
        fill="#3A85E9"
      />
      <path
        d="M34.5156 31.8721H29.6195C29.6195 28.1143 29.251 24.4696 28.5177 21.0475C27.8245 17.7858 26.8321 14.8708 25.5771 12.3826C24.395 10.0404 22.9904 8.15054 21.5165 6.91375C20.1593 5.77911 18.7693 5.20267 17.3792 5.20267C15.9929 5.20267 14.5992 5.77911 13.242 6.91375C11.7681 8.15054 10.3634 10.0404 9.18138 12.3826C7.92634 14.8708 6.93764 17.7858 6.2408 21.0475C5.50748 24.4733 5.139 28.1143 5.139 31.8721H0.239258C0.239258 23.7327 1.85913 16.0383 4.80335 10.2155C6.29918 7.24575 8.07592 4.89256 10.0789 3.21431C11.1624 2.30587 12.3117 1.60174 13.4901 1.12381C14.7561 0.613037 16.0622 0.354004 17.3792 0.354004C18.6963 0.354004 20.0024 0.613037 21.2684 1.12381C22.4468 1.60174 23.596 2.30587 24.6796 3.21431C26.6825 4.89256 28.4593 7.24575 29.9551 10.2155C32.8957 16.0383 34.5156 23.7327 34.5156 31.8721Z"
        fill="#1AABE7"
      />
      <path
        d="M49.1228 22.6573C49.619 22.6573 50.0604 22.65 50.4508 22.6281C50.8375 22.6098 51.1586 22.5661 51.414 22.4967V18.9797C51.268 18.9031 51.0199 18.8411 50.6697 18.79C50.3267 18.7316 49.9145 18.7025 49.4293 18.7025C49.1155 18.7025 48.7798 18.728 48.4223 18.7754C48.0611 18.8156 47.7291 18.9068 47.43 19.0527C47.1381 19.1986 46.8936 19.3993 46.7003 19.651C46.5033 19.8955 46.4084 20.2202 46.4084 20.6288C46.4084 21.3986 46.6492 21.9276 47.1381 22.2195C47.6233 22.5113 48.2837 22.6573 49.1228 22.6573ZM48.9331 12.0479C49.7868 12.0479 50.5092 12.161 51.0929 12.3835C51.6766 12.5988 52.1436 12.9089 52.4939 13.3175C52.8514 13.7188 53.1068 14.1931 53.2527 14.7476C53.406 15.3022 53.4862 15.9151 53.4862 16.5864V23.9853C53.3111 24.0145 53.0557 24.0582 52.7274 24.1166C52.3954 24.1677 52.0269 24.2078 51.6183 24.248C51.2097 24.2954 50.7646 24.3392 50.2903 24.3793C49.8123 24.4158 49.3344 24.4377 48.8601 24.4377C48.1961 24.4377 47.5832 24.3683 47.0213 24.2334C46.4558 24.0984 45.9706 23.8831 45.562 23.5912C45.1534 23.2921 44.8323 22.8981 44.5988 22.4092C44.3726 21.9239 44.2632 21.3402 44.2632 20.658C44.2632 20.0086 44.3945 19.4467 44.6572 18.9797C44.9272 18.5054 45.2884 18.126 45.7371 17.8414C46.1932 17.5496 46.7222 17.338 47.3278 17.1993C47.9407 17.0643 48.5828 16.995 49.2541 16.995C49.4657 16.995 49.6846 17.006 49.9108 17.0242C50.1443 17.0461 50.3632 17.0753 50.5675 17.1118C50.7718 17.1519 50.947 17.1847 51.0929 17.2139C51.2461 17.2431 51.3556 17.2686 51.414 17.2869V16.6886C51.414 16.3493 51.3738 16.01 51.2972 15.667C51.2279 15.3168 51.0929 15.0103 50.8886 14.7476C50.6916 14.485 50.4253 14.277 50.086 14.1201C49.743 13.9559 49.3016 13.872 48.758 13.872C48.0575 13.872 47.4445 13.9231 46.9192 14.018C46.3938 14.1165 46.0034 14.2186 45.7517 14.3244L45.489 12.5732C45.77 12.4492 46.226 12.3324 46.8608 12.223C47.5029 12.1062 48.1925 12.0479 48.9331 12.0479Z"
        fill="#1AABE7"
      />
      <path
        d="M60.9108 24.4232C59.6047 24.3831 58.6816 24.1022 58.138 23.5768C57.5908 23.0441 57.3208 22.216 57.3208 21.0959V6.88193L59.4368 6.51709V20.7457C59.4368 21.0959 59.466 21.3878 59.5244 21.6213C59.5828 21.8475 59.6776 22.0262 59.8163 22.1612C59.9622 22.2999 60.1446 22.402 60.3708 22.4677C60.5934 22.537 60.8743 22.5954 61.2172 22.6428L60.9108 24.4232Z"
        fill="#1AABE7"
      />
      <path
        d="M72.1634 18.2938C72.1634 16.9147 71.8204 15.853 71.1418 15.1124C70.4596 14.3645 69.5511 13.9887 68.4129 13.9887C67.7707 13.9887 67.2673 14.0142 66.9097 14.0617C66.5595 14.1018 66.2749 14.1492 66.0633 14.2076V21.6794C66.326 21.8947 66.6945 22.1026 67.1724 22.3069C67.6576 22.504 68.194 22.5988 68.7777 22.5988C69.3797 22.5988 69.8941 22.4894 70.3246 22.2632C70.7624 22.0406 71.1163 21.7378 71.3899 21.3584C71.6599 20.9717 71.8569 20.512 71.9736 19.9866C72.0977 19.4612 72.1634 18.8994 72.1634 18.2938ZM74.3816 18.2938C74.3816 19.1913 74.2575 20.0158 74.0167 20.7746C73.7832 21.5335 73.4366 22.1866 72.9806 22.7302C72.5318 23.2774 71.9773 23.7043 71.3169 24.0144C70.6529 24.3135 69.9014 24.4668 69.055 24.4668C68.3618 24.4668 67.7561 24.3719 67.2308 24.1895C66.7127 24.0071 66.326 23.832 66.0633 23.6641V28.407H63.9473V12.7045C64.4434 12.5877 65.0637 12.4564 65.8152 12.3105C66.5741 12.1645 67.4424 12.0916 68.4274 12.0916C69.3395 12.0916 70.1641 12.2375 70.8937 12.5294C71.6234 12.8139 72.2436 13.2225 72.7617 13.7552C73.2761 14.2915 73.6738 14.9446 73.9584 15.7107C74.2393 16.4696 74.3816 17.3306 74.3816 18.2938Z"
        fill="#1AABE7"
      />
      <path
        d="M77.7285 24.1897V6.88193L79.8446 6.51709V12.5734C80.2422 12.4201 80.6654 12.3034 81.1142 12.2231C81.5593 12.1356 82.0044 12.0918 82.4422 12.0918C83.3835 12.0918 84.1679 12.2304 84.7917 12.5004C85.412 12.7631 85.9081 13.1352 86.2803 13.6095C86.6487 14.0874 86.9114 14.6602 87.0683 15.3315C87.2215 15.9955 87.3018 16.7288 87.3018 17.5351V24.1897H85.1712V17.9875C85.1712 17.2578 85.1201 16.6376 85.0252 16.1196C84.9267 15.6051 84.7625 15.1929 84.529 14.8791C84.3029 14.5581 84.0037 14.3246 83.6243 14.1786C83.2448 14.0327 82.7705 13.9597 82.2087 13.9597C81.9825 13.9597 81.749 13.9743 81.5082 14.0035C81.2638 14.0327 81.0303 14.0692 80.8077 14.1057C80.5925 14.1458 80.3991 14.1896 80.224 14.237C80.0489 14.2881 79.9212 14.3246 79.8446 14.3538V24.1897H77.7285Z"
        fill="#1AABE7"
      />
      <path
        d="M95.1125 22.6573C95.6087 22.6573 96.0502 22.65 96.4405 22.6281C96.8273 22.6098 97.1483 22.5661 97.4037 22.4967V18.9797C97.2578 18.9031 97.0097 18.8411 96.6594 18.79C96.3165 18.7316 95.9042 18.7025 95.419 18.7025C95.1052 18.7025 94.7696 18.728 94.4121 18.7754C94.0509 18.8156 93.7189 18.9068 93.4197 19.0527C93.1278 19.1986 92.8834 19.3993 92.69 19.651C92.493 19.8955 92.3982 20.2202 92.3982 20.6288C92.3982 21.3986 92.639 21.9276 93.1278 22.2195C93.6131 22.5113 94.2734 22.6573 95.1125 22.6573ZM94.9228 12.0479C95.7765 12.0479 96.4989 12.161 97.0827 12.3835C97.6664 12.5988 98.1334 12.9089 98.4836 13.3175C98.8412 13.7188 99.0965 14.1931 99.2425 14.7476C99.3957 15.3022 99.476 15.9151 99.476 16.5864V23.9853C99.3009 24.0145 99.0455 24.0582 98.7171 24.1166C98.3851 24.1677 98.0166 24.2078 97.608 24.248C97.1994 24.2954 96.7543 24.3392 96.28 24.3793C95.8021 24.4158 95.3241 24.4377 94.8499 24.4377C94.1859 24.4377 93.5729 24.3683 93.0111 24.2334C92.4456 24.0984 91.9604 23.8831 91.5517 23.5912C91.1431 23.2921 90.8221 22.8981 90.5886 22.4092C90.3624 21.9239 90.2529 21.3402 90.2529 20.658C90.2529 20.0086 90.3843 19.4467 90.647 18.9797C90.9169 18.5054 91.2781 18.126 91.7269 17.8414C92.1829 17.5496 92.7119 17.338 93.3175 17.1993C93.9305 17.0643 94.5726 16.995 95.2439 16.995C95.4555 16.995 95.6744 17.006 95.9006 17.0242C96.1341 17.0461 96.353 17.0753 96.5573 17.1118C96.7616 17.1519 96.9367 17.1847 97.0827 17.2139C97.2359 17.2431 97.3453 17.2686 97.4037 17.2869V16.6886C97.4037 16.3493 97.3636 16.01 97.287 15.667C97.2176 15.3168 97.0827 15.0103 96.8783 14.7476C96.6813 14.485 96.415 14.277 96.0757 14.1201C95.7328 13.9559 95.2913 13.872 94.7477 13.872C94.0472 13.872 93.4343 13.9231 92.9089 14.018C92.3836 14.1165 91.9932 14.2186 91.7415 14.3244L91.4788 12.5732C91.7597 12.4492 92.2157 12.3324 92.8506 12.223C93.4927 12.1062 94.1822 12.0479 94.9228 12.0479Z"
        fill="#1AABE7"
      />
      <path
        d="M103.346 12.6899C103.831 12.5658 104.474 12.4345 105.273 12.2959C106.079 12.1609 107.009 12.0916 108.06 12.0916C109.001 12.0916 109.786 12.2302 110.409 12.5002C111.03 12.7629 111.526 13.135 111.898 13.6093C112.266 14.0872 112.529 14.66 112.686 15.3313C112.839 15.9953 112.919 16.7286 112.919 17.5349V24.1895H110.789V17.9873C110.789 17.2576 110.738 16.6374 110.643 16.1193C110.544 15.6049 110.38 15.1927 110.147 14.8789C109.921 14.5578 109.621 14.3243 109.242 14.1784C108.863 14.0325 108.388 13.9595 107.826 13.9595C107.6 13.9595 107.367 13.9705 107.126 13.9887C106.881 13.9996 106.648 14.0179 106.425 14.0471C106.21 14.069 106.017 14.0909 105.842 14.12C105.667 14.1492 105.539 14.1748 105.462 14.193V24.1895H103.346V12.6899Z"
        className="logo-path"
      />
      <path
        d="M127.234 18.2646C127.234 19.2096 127.096 20.0596 126.826 20.8185C126.552 21.5774 126.169 22.2304 125.673 22.774C125.184 23.3213 124.597 23.7445 123.907 24.0436C123.225 24.3355 122.481 24.4814 121.674 24.4814C120.875 24.4814 120.131 24.3355 119.442 24.0436C118.759 23.7445 118.172 23.3213 117.676 22.774C117.187 22.2304 116.807 21.5774 116.538 20.8185C116.264 20.0596 116.129 19.2096 116.129 18.2646C116.129 17.3416 116.264 16.4952 116.538 15.7254C116.807 14.9592 117.187 14.3025 117.676 13.7553C118.172 13.2117 118.759 12.7921 119.442 12.5002C120.131 12.2011 120.875 12.0479 121.674 12.0479C122.481 12.0479 123.225 12.2011 123.907 12.5002C124.597 12.7921 125.184 13.2117 125.673 13.7553C126.169 14.3025 126.552 14.9592 126.826 15.7254C127.096 16.4952 127.234 17.3416 127.234 18.2646ZM125.031 18.2646C125.031 16.933 124.728 15.8786 124.126 15.0979C123.531 14.3098 122.714 13.9158 121.674 13.9158C120.642 13.9158 119.825 14.3098 119.223 15.0979C118.628 15.8786 118.333 16.933 118.333 18.2646C118.333 19.6072 118.628 20.6689 119.223 21.446C119.825 22.2268 120.642 22.6135 121.674 22.6135C122.714 22.6135 123.531 22.2268 124.126 21.446C124.728 20.6689 125.031 19.6072 125.031 18.2646Z"
        className="logo-path"
      />
      <path
        d="M130.59 12.6899C131.075 12.5658 131.717 12.4345 132.516 12.2959C133.312 12.1609 134.231 12.0916 135.274 12.0916C136.033 12.0916 136.668 12.1937 137.186 12.398C137.711 12.6023 138.149 12.9051 138.499 13.3028C138.594 13.2262 138.755 13.1204 138.981 12.9818C139.215 12.8468 139.495 12.7154 139.827 12.5877C140.167 12.4527 140.543 12.336 140.951 12.2375C141.36 12.1426 141.801 12.0916 142.279 12.0916C143.184 12.0916 143.921 12.2302 144.497 12.5002C145.081 12.7629 145.534 13.1386 145.855 13.6239C146.183 14.1127 146.409 14.6892 146.526 15.3605C146.643 16.0245 146.701 16.7469 146.701 17.5349V24.1895H144.57V17.9873C144.57 17.2978 144.534 16.7031 144.468 16.2069C144.399 15.7034 144.268 15.2839 144.074 14.9519C143.888 14.6126 143.629 14.3645 143.301 14.2076C142.98 14.0434 142.56 13.9595 142.046 13.9595C141.334 13.9595 140.747 14.058 140.28 14.2514C139.813 14.4374 139.495 14.6089 139.331 14.7621C139.455 15.1525 139.55 15.5867 139.609 16.061C139.667 16.528 139.696 17.0205 139.696 17.5349V24.1895H137.58V17.9873C137.58 17.2978 137.54 16.7031 137.463 16.2069C137.383 15.7034 137.248 15.2839 137.055 14.9519C136.869 14.6126 136.61 14.3645 136.281 14.2076C135.96 14.0434 135.552 13.9595 135.055 13.9595C134.84 13.9595 134.61 13.9705 134.37 13.9887C134.125 13.9996 133.892 14.0179 133.669 14.0471C133.454 14.069 133.253 14.0909 133.071 14.12C132.885 14.1492 132.764 14.1748 132.706 14.193V24.1895H130.59V12.6899Z"
        className="logo-path"
      />
      <path
        d="M152.683 24.1897H150.567V12.3399H152.683V24.1897ZM151.617 10.2092C151.238 10.2092 150.91 10.0852 150.64 9.82979C150.377 9.57805 150.246 9.23876 150.246 8.80825C150.246 8.39234 150.377 8.05669 150.64 7.8013C150.91 7.54957 151.238 7.42188 151.617 7.42188C151.997 7.42188 152.318 7.54957 152.581 7.8013C152.843 8.05669 152.975 8.39234 152.975 8.80825C152.975 9.23876 152.843 9.57805 152.581 9.82979C152.318 10.0852 151.997 10.2092 151.617 10.2092Z"
        className="logo-path"
      />
      <path
        d="M161.845 24.4669C160.882 24.4669 160.039 24.3136 159.321 24.0145C158.598 23.7043 157.993 23.2775 157.496 22.7302C157.008 22.1866 156.643 21.5409 156.402 20.7893C156.158 20.0305 156.037 19.1986 156.037 18.2938C156.037 17.3817 156.168 16.5426 156.431 15.7838C156.694 15.0249 157.066 14.3682 157.555 13.8137C158.04 13.2591 158.635 12.8286 159.335 12.5148C160.043 12.2047 160.831 12.0479 161.699 12.0479C162.232 12.0479 162.765 12.0989 163.29 12.1938C163.823 12.2813 164.333 12.4236 164.822 12.617L164.341 14.412C164.027 14.2661 163.662 14.1493 163.246 14.0617C162.827 13.9669 162.378 13.9158 161.904 13.9158C160.725 13.9158 159.82 14.2916 159.189 15.0395C158.554 15.7801 158.241 16.8637 158.241 18.2938C158.241 18.9287 158.314 19.5124 158.46 20.045C158.606 20.5814 158.832 21.0374 159.146 21.4168C159.456 21.7963 159.853 22.0881 160.342 22.2924C160.827 22.4967 161.415 22.5989 162.108 22.5989C162.67 22.5989 163.181 22.5478 163.64 22.4384C164.096 22.3326 164.45 22.2158 164.706 22.0881L165.012 23.8685C164.884 23.9488 164.706 24.0218 164.472 24.0874C164.246 24.1567 163.991 24.2115 163.699 24.2625C163.414 24.3209 163.108 24.3683 162.779 24.4085C162.458 24.445 162.144 24.4669 161.845 24.4669Z"
        className="logo-path"
      />
      <path
        d="M170.408 22.6573C171.273 22.6573 171.915 22.5478 172.335 22.3216C172.751 22.0991 172.962 21.7342 172.962 21.2271C172.962 20.7127 172.751 20.3041 172.335 20.0013C171.926 19.7021 171.255 19.3592 170.321 18.9797C169.861 18.7973 169.423 18.6112 169.008 18.4252C168.588 18.2318 168.223 18.0093 167.913 17.7539C167.61 17.4912 167.366 17.1811 167.183 16.8199C167.008 16.4514 166.921 15.999 166.921 15.4627C166.921 14.412 167.304 13.5802 168.074 12.9672C168.851 12.3543 169.912 12.0479 171.255 12.0479C171.583 12.0479 171.915 12.0697 172.247 12.1062C172.587 12.1464 172.897 12.1938 173.181 12.2522C173.473 12.3032 173.725 12.3543 173.94 12.4127C174.163 12.4711 174.338 12.5258 174.465 12.5732L174.071 14.3974C173.845 14.2733 173.491 14.1457 173.006 14.018C172.517 13.8939 171.934 13.8282 171.255 13.8282C170.66 13.8282 170.139 13.945 169.693 14.1785C169.256 14.412 169.037 14.7841 169.037 15.2876C169.037 15.543 169.084 15.7692 169.183 15.9735C169.278 16.1705 169.431 16.3493 169.635 16.5134C169.847 16.6703 170.106 16.8163 170.408 16.9512C170.708 17.0899 171.073 17.2285 171.503 17.3745C172.058 17.5897 172.554 17.7977 172.992 18.002C173.437 18.2063 173.816 18.4471 174.13 18.717C174.44 18.9907 174.677 19.3227 174.845 19.7094C175.009 20.0998 175.093 20.5777 175.093 21.1396C175.093 22.2304 174.684 23.0586 173.867 23.6204C173.057 24.1859 171.904 24.4669 170.408 24.4669C169.358 24.4669 168.533 24.3793 167.942 24.2042C167.348 24.0291 166.943 23.8941 166.731 23.7956L167.125 21.986C167.366 22.0735 167.752 22.2122 168.278 22.3946C168.811 22.5697 169.522 22.6573 170.408 22.6573Z"
        className="logo-path"
      />
    </svg>
  );
};

export default DarkLogo;
