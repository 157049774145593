import qs from "qs";
import axiosInstance, { axiosInstanceWithoutAuthToken } from "./Axios";

export interface IBaseAPIParams {
  url: string;
  method?: string;
  params?: object | null;
  data?: object | null;
  needToken?: boolean;
}

export class BaseAPI {
  static callAPI = async ({
    url,
    method = "get",
    params = null,
    data = null,
    needToken = true,
  }: IBaseAPIParams) => {
    const paramsSerializer = (params: object | null) => {
      return qs.stringify(params, {
        skipNulls: false, // Include null values in serialization
        encode: false, // Disable URL encoding for simplicity, adjust as needed
      });
    };

    let res = null;
    try {
      if (needToken) {
        res = await axiosInstance({
          url: url,
          method: method,
          data: data,
          params: params,
          paramsSerializer,
        });
      } else {
        res = await axiosInstanceWithoutAuthToken({
          url: url,
          method: method,
          data: data,
          params: params,
          paramsSerializer,
        });
      }
    } catch (err: any) {
      console.log("Something went wrong while calling API.");
      let error = "Something went wrong";
      if (err?.response?.data) error = err?.response?.data?.error ?? error;

      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      )
        location.href = location.origin + "/disconnect";

      return {
        data: null,
        error: error,
      };
    }

    if (res.status !== 200) {
      console.log("Unexpected api response status code", res.status);
      return {
        data: null,
        error: "Something went wrong",
      };
    }

    return {
      data: res.data,
      error: null,
    };
  };
}
