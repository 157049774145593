import React from "react";

const CronosIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="23"
      viewBox="0 0 19 23"
      fill="none"
      className="chain-icon fill"
    >
      <path
        d="M9.43642 0.0834961L0 5.5835V16.5835L9.43642 22.0835L18.8571 16.5835V5.5835L9.43642 0.0834961ZM16.0623 14.9509L9.42072 18.8184L2.79481 14.9509V7.21606L9.43642 3.34863L16.0623 7.21606V14.9509Z"
        fill="url(#paint0_linear_9358_117880)"
      />
      <path
        d="M9.4366 22.0835L18.8573 16.5835V5.5835L9.4366 0.0834961V3.34863L16.0625 7.21606V14.9668L9.4209 18.8342V22.0835H9.4366Z"
        fill="url(#paint1_linear_9358_117880)"
      />
      <path
        d="M9.42072 0.0834961L0 5.5835V16.5835L9.42072 22.0835V18.8184L2.79481 14.9509V7.20021L9.42072 3.34863V0.0834961Z"
        fill="url(#paint2_linear_9358_117880)"
      />
      <path
        d="M13.8328 13.6514L9.43645 16.2191L5.02441 13.6514V8.51597L9.43645 5.94824L13.8328 8.51597L11.9957 9.59378L9.42075 8.08801L6.86145 9.59378V12.5895L9.43645 14.0952L12.0114 12.5895L13.8328 13.6514Z"
        fill="url(#paint3_linear_9358_117880)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9358_117880"
          x1="22.2496"
          y1="16.2953"
          x2="1.59642"
          y2="0.406204"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9358_117880"
          x1="20.5549"
          y1="16.2953"
          x2="6.22641"
          y2="10.779"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9358_117880"
          x1="11.1155"
          y1="16.2953"
          x2="-3.19532"
          y2="10.795"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9358_117880"
          x1="15.4174"
          y1="13.5169"
          x2="5.77399"
          y2="6.09387"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C3FF" />
          <stop offset="0.796666" stopColor="#79DEFE" />
          <stop offset="1" stopColor="#79DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CronosIcon;
