import { IGetSmartHoldingsParams } from "../../@types/api";
import { BaseAPI } from "./BaseAPI";

export class SmartHoldingsAPI {
  static getSmartHoldings = async ({
    chain,
    page,
    page_size,
    metric,
    ordering = "",
  }: IGetSmartHoldingsParams) => {
    return BaseAPI.callAPI({
      url: "/wallet/smart_holdings/",
      params: {
        chain,
        metric,
        page,
        page_size,
        ordering,
      },
    });
  };
}
