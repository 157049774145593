import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { IAuthQuery, IAuthResult } from "../../@types/redux";
import { AuthAPI } from "../../utils/api/AuthAPI";
import { LocalStorageKeys, LocalStorages } from "../../utils/localStorages";
import { snackbarActions } from "./snackbarSlice";

const initialState = {
  loading: false,
  data: {
    access_token: "",
    refresh_token: "",
    ref_code: "",
    subscr_type: "0",
  } as IAuthResult,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.data.access_token = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<IAuthResult>) => {
      LocalStorages.setAccessToken(action.payload.access_token);
      localStorage.setItem(
        LocalStorageKeys.refresh_token,
        action.payload.refresh_token
      );
      localStorage.setItem(LocalStorageKeys.ref_code, action.payload.ref_code);
      localStorage.setItem(
        LocalStorageKeys.wallet_address,
        action.payload.wallet_address
      );
      localStorage.setItem(
        LocalStorageKeys.subscr_type,
        action.payload.subscr_type
      );
      if (action.payload.show_tutorial)
        localStorage.setItem(LocalStorageKeys.show_tutorial, "true");
      state.data = action.payload;
      state.loading = false;
    },
    signin: (state, _: PayloadAction<IAuthQuery>) => {
      state.loading = true;
    },
    signout: (state, _) => {
      LocalStorages.removeAccessToken();
      state.data.access_token = "";
    },
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;

// Sagas

function* signin(action: PayloadAction<IAuthQuery>): any {
  const res = yield call(AuthAPI.signin as any, {
    wallet_address: action.payload.wallet_address,
    signature: action.payload.signature,
    ref_code: action.payload.ref_code,
  });
  if (!res.error) {
    yield put(
      authActions.setUserInfo({
        ...res.data,
        wallet_address: action.payload.wallet_address,
      })
    );
    if (action.payload.target_link) location.href = action.payload.target_link;
    else location.reload();
  } else {
    yield put(
      snackbarActions.showSnackBar({
        content: res.error,
        color: "error",
      })
    );
  }
}

export function* authSaga() {
  yield all([takeLatest(authActions.signin, signin)]);
}
