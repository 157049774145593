import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  IWatchlistActiveTab,
  IWatchlistQuery,
  IWatchlistWallets,
  IWatchlistTokens,
} from "../../@types/redux";
import { WatchlistAPI } from "../../utils/api/WatchlistAPI";
import { WatchlistTabOptions } from "../../utils/constants";

export const watchlistWalletsDefaultQuery = {
  page_size: 20,
  page: 1,
};

export const watchlistTokensDefaultQuery = {
  page_size: 20,
  page: 1,
};

interface IWatchlistState {
  isRefreshing: boolean;
  activeTab: string;
  wallets: {
    loading: boolean;
    wallets: [];
    total_count: number;
    total_page: number;
    query: {
      page_size: number;
      page: number;
    };
  };
  tokens: {
    loading: boolean;
    total_count: number;
    total_page: number;
    tokens: [];
    query: {
      page_size: number;
      page: number;
    };
  };
}

const initialState: IWatchlistState = {
  isRefreshing: false,
  activeTab: "",
  wallets: {
    loading: false,
    total_count: 0,
    total_page: 0,
    wallets: [],
    query: { ...watchlistWalletsDefaultQuery },
  },
  tokens: {
    loading: false,
    total_count: 0,
    total_page: 0,
    tokens: [],
    query: { ...watchlistTokensDefaultQuery },
  },
};

const watchlistSlice = createSlice({
  name: "watchlist",
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<IWatchlistActiveTab>) => {
      const tab = action.payload.tab;
      state.activeTab = tab;

      if (tab === WatchlistTabOptions.wallet)
        state.wallets.query = { ...watchlistWalletsDefaultQuery };
      else if (tab === WatchlistTabOptions.token)
        state.tokens.query = { ...watchlistTokensDefaultQuery };
    },

    getWallets: (state, _: PayloadAction<IWatchlistQuery>) => {
      if (!state.isRefreshing) {
        state.wallets.loading = true;
        state.wallets.wallets = [];
      }
    },

    setWallets: (state, action: PayloadAction<IWatchlistWallets>) => {
      state.isRefreshing = false;
      state.wallets.loading = false;
      state.wallets.total_count = action.payload.total_count;
      state.wallets.total_page = action.payload.total_page;
      state.wallets.wallets = action.payload.wallets;
    },

    setWalletsQuery: (state, action: PayloadAction<IWatchlistQuery>) => {
      state.wallets.query.page_size = action.payload.page_size;
      state.wallets.query.page = action.payload.page;
    },

    resetWallets: (state, _) => {
      state.isRefreshing = true;
      state.wallets.query = { ...watchlistWalletsDefaultQuery };
    },

    unWatchWallet: (state, _) => {
      state.wallets.loading = true;
      state.wallets.total_count = 0;
    },

    getTokens: (state, _: PayloadAction<IWatchlistQuery>) => {
      if (!state.isRefreshing) {
        state.tokens.loading = true;
        state.tokens.tokens = [];
      }
    },

    setTokens: (state, action: PayloadAction<IWatchlistTokens>) => {
      state.isRefreshing = false;
      state.tokens.loading = false;
      state.tokens.total_count = action.payload.total_count;
      state.tokens.total_page = action.payload.total_page;
      state.tokens.tokens = action.payload.tokens;
    },

    setTokensQuery: (state, action: PayloadAction<IWatchlistQuery>) => {
      state.tokens.query.page_size = action.payload.page_size;
      state.tokens.query.page = action.payload.page;
    },

    resetTokens: (state, _) => {
      state.isRefreshing = true;
      state.tokens.query = watchlistTokensDefaultQuery;
    },

    unWatchToken: (state, _) => {
      state.tokens.loading = true;
    },
  },
});

const watchlistActions = watchlistSlice.actions;
const watchlistReducer = watchlistSlice.reducer;

export { watchlistActions, watchlistReducer };

// Sagas

function* getWallets(action: PayloadAction<IWatchlistQuery>): any {
  const res = yield call(WatchlistAPI.getWallets as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      watchlistActions.setWallets({
        ...res.data,
      })
    );
  } else {
    yield put(
      watchlistActions.setWallets({
        total_count: 0,
        total_page: 0,
        wallets: [],
      })
    );
  }
}

function* getTokens(action: PayloadAction<IWatchlistQuery>): any {
  const res = yield call(WatchlistAPI.getTokens as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      watchlistActions.setTokens({
        ...res.data,
      })
    );
  } else {
    yield put(
      watchlistActions.setTokens({
        total_count: 0,
        total_page: 0,
        tokens: [],
      })
    );
  }
}

export function* watchlistSaga() {
  yield all([
    takeLatest(watchlistActions.getWallets, getWallets),
    takeLatest(watchlistActions.getTokens, getTokens),
  ]);
}
