import { BaseAPI } from "./BaseAPI";

export class PerpDexAPI {
  static getGmxData = async () => {
    return BaseAPI.callAPI({
      url: "/dex/perp/gmx/data/",
    });
  };

  static getVolumeDistsData = async () => {
    return BaseAPI.callAPI({
      url: "/dex/perp/gmx/volume_dists/",
    });
  };

  static getOpenInterests = async () => {
    return BaseAPI.callAPI({
      url: "/dex/perp/gmx/open_interests/",
    });
  };

  static getOpenInterestsTop = async () => {
    return BaseAPI.callAPI({
      url: "/dex/perp/gmx/open_interests_top/",
    });
  };

  static getTradingVolumes = async () => {
    return BaseAPI.callAPI({
      url: "/dex/perp/gmx/trading_volumes/",
    });
  };

  static getLiquidations = async () => {
    return BaseAPI.callAPI({
      url: "/dex/perp/gmx/liquidations/",
    });
  };

  static getOpenTradesList = async ({
    page = 1,
    page_size = 50,
    ordering = "",
  }) => {
    return BaseAPI.callAPI({
      url: "/dex/perp/gmx_trades/arb_opens/",
      params: {
        page,
        page_size,
        ordering,
      },
    });
  };
}
