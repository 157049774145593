import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { PerpDexAPI } from "../../utils/api/PerpDexAPI";

const initialState = {
  gmxData: {
    data: {
      total_volume: 0,
      unique_traders: 0,
      daily_volume: 0,
      weekly_volume: 0,
      monthly_volume: 0,
      daily_unique_traders: 0,
      weekly_unique_traders: 0,
      monthly_unique_traders: 0,
      avg_trade_size: 0,
      long_collateral: 0,
      short_collateral: 0,
    },
    loading: false,
  },
  volumeDists: {
    data: {
      total_volume: 0,
      distribution: [] as {
        trade_type: string;
        total_volume: number;
      }[],
    },
    loading: false,
  },
  openInterests: {
    data: {
      long_oi: 0,
      short_oi: 0,
      history: [] as {
        date: string;
        long_oi: number;
        short_oi: number;
      }[],
    },
    loading: false,
  },
  openInterestTop: {
    data: {
      long_oi: 0,
      short_oi: 0,
      history: [] as {
        date: string;
        long_oi: number;
        short_oi: number;
      }[],
    },
    loading: false,
  },
  tradingVolumes: {
    data: {
      history: [] as {
        date: string;
        token: string;
        total_volume: number;
      }[],
    },
    loading: false,
  },
  liquidations: {
    data: {
      daily_longs_liquidated: 0,
      daily_shorts_liquidated: 0,
      weekly_longs_liquidated: 0,
      weekly_shorts_liquidated: 0,
      monthly_longs_liquidated: 0,
      monthly_shorts_liquidated: 0,
      history: [] as {
        date: string;
        avg_price: number;
        longs_liquidated: number;
        shorts_liquidated: number;
      }[],
    },
    loading: false,
  },
};

const perpDexSlice = createSlice({
  name: "perpDex",
  initialState,
  reducers: {
    getGmxData: (state, _) => {
      state.gmxData.data = initialState.gmxData.data;
      state.gmxData.loading = true;
    },
    setGmxData: (state, action: PayloadAction<any>) => {
      state.gmxData.loading = false;
      state.gmxData.data = action.payload;
    },
    getVolumeDists: (state, _) => {
      state.volumeDists.data = initialState.volumeDists.data;
      state.volumeDists.loading = true;
    },
    setVolumeDists: (state, action: PayloadAction<any>) => {
      state.volumeDists.loading = false;
      state.volumeDists.data = action.payload;
    },
    getOpenInterests: (state, _) => {
      state.openInterests.data = initialState.openInterests.data;
      state.openInterests.loading = true;
    },
    setOpenInterests: (state, action: PayloadAction<any>) => {
      state.openInterests.loading = false;
      state.openInterests.data = action.payload;
      state.openInterests.data.history.reverse();
    },
    getOpenInterestsTop: (state, _) => {
      state.openInterestTop.data = initialState.openInterestTop.data;
      state.openInterestTop.loading = true;
    },
    setOpenInterestsTop: (state, action: PayloadAction<any>) => {
      state.openInterestTop.loading = false;
      state.openInterestTop.data = action.payload;
      state.openInterestTop.data.history.reverse();
    },
    getTradingVolumes: (state, _) => {
      state.tradingVolumes.data = initialState.tradingVolumes.data;
      state.tradingVolumes.loading = true;
    },
    setTradingVolumes: (state, action: PayloadAction<any>) => {
      state.tradingVolumes.loading = false;
      state.tradingVolumes.data = action.payload;
      state.tradingVolumes.data.history.reverse();
    },
    getLiquidations: (state, _) => {
      state.liquidations.data = initialState.liquidations.data;
      state.liquidations.loading = true;
    },
    setLiquidations: (state, action: PayloadAction<any>) => {
      state.liquidations.loading = false;
      state.liquidations.data = action.payload;
      state.liquidations.data.history.reverse();
    },
  },
});

export const perpDexActions = perpDexSlice.actions;
export const perpDexReducer = perpDexSlice.reducer;

// Sagas

function* getGmxData(action: any): any {
  const res = yield call(PerpDexAPI.getGmxData as any, { ...action.payload });

  if (!res.error) yield put(perpDexActions.setGmxData(res.data));
  else yield put(perpDexActions.setGmxData(initialState.gmxData.data));
}

function* getVolumeDists(action: any): any {
  const res = yield call(PerpDexAPI.getVolumeDistsData as any, {
    ...action.payload,
  });

  if (!res.error) yield put(perpDexActions.setVolumeDists(res.data));
  else yield put(perpDexActions.setVolumeDists(initialState.volumeDists.data));
}
function* getOpenInterests(action: any): any {
  const res = yield call(PerpDexAPI.getOpenInterests as any, {
    ...action.payload,
  });

  if (!res.error) yield put(perpDexActions.setOpenInterests(res.data));
  else
    yield put(perpDexActions.setOpenInterests(initialState.openInterests.data));
}
function* getOpenInterestsTop(action: any): any {
  const res = yield call(PerpDexAPI.getOpenInterestsTop as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(perpDexActions.setOpenInterestsTop(res.data));
  } else {
    yield put(
      perpDexActions.setOpenInterestsTop(initialState.openInterestTop.data)
    );
  }
}
function* getTradingVolumes(action: any): any {
  const res = yield call(PerpDexAPI.getTradingVolumes as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(perpDexActions.setTradingVolumes(res.data));
  } else {
    yield put(
      perpDexActions.setTradingVolumes(initialState.tradingVolumes.data)
    );
  }
}
function* getLiquidations(action: any): any {
  const res = yield call(PerpDexAPI.getLiquidations as any, {
    ...action.payload,
  });

  if (!res.error) yield put(perpDexActions.setLiquidations(res.data));
  else
    yield put(perpDexActions.setLiquidations(initialState.liquidations.data));
}

export function* perpDexSaga() {
  yield all([
    takeLatest(perpDexActions.getGmxData, getGmxData),
    takeLatest(perpDexActions.getVolumeDists, getVolumeDists),
    takeLatest(perpDexActions.getOpenInterests, getOpenInterests),
    takeLatest(perpDexActions.getOpenInterestsTop, getOpenInterestsTop),
    takeLatest(perpDexActions.getTradingVolumes, getTradingVolumes),
    takeLatest(perpDexActions.getLiquidations, getLiquidations),
  ]);
}
